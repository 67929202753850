// Font Size
$sizes: 10, 12, 14, 16, 18, 20, 22, 24;

@each $size in $sizes {
  .font-size-#{$size} {
    font-size: $size + px !important;
  }
}

// Grid Style
$grid-columns: 12;

@for $size from 1 through $grid-columns {
  .grid-#{$size} {
    display: grid;
    grid-template-columns: repeat(#{$size}, 1fr);
  }
}

@for $size from 1 through $grid-columns {
  .grid-col-gap-#{$size} {
    column-gap: $size + px;
  }
}

@mixin modal-fullscreen() {
  padding: 0 !important; // override inline padding-right added from js

  .modal-dialog {
    width: 100%;
    max-width: none;
    height: 100%;
    max-height: 100vh !important;
    margin: 0;
  }

  .modal-content {
    height: 100%;
    max-height: 100vh !important;
    border: 0;
    border-radius: 0;
  }

  .modal-body {
    overflow-y: auto;
  }
}
.modal-fullscreen {
  @include modal-fullscreen();
}
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-down($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .modal-fullscreen#{$infix} {
      @include modal-fullscreen();
    }
  }
}

// Font Size
$priority-color: (
  // overdue: #c00000,
  overdue: #f90404,
  due: #c65911,
  normal: #0d568a,
  low: #00b050,
) !default;

@mixin priority-color($name, $color) {
  .text-#{$name} {
    color: $color;
  }
}
@each $theme, $color in $priority-color {
  @include priority-color($theme, $color);
}
