/* You can add global styles to this file, and also import other style files */
@import "/src/app/scss/styles.scss";


/* TO BE CHANGED*/
.applicant-sidebar.yellow-heading .card-header .btn-link:not(.btn-toggle-arrow){
    background-color: #C19200!important;
}
.applicant-sidebar.yellow-heading .text-success-light{
    color: #C19200!important;
}

.applicant-sidebar.yellow-heading .module-sidebar-heading.module-success{
    border-color: #C19200!important;
}

.cursor-pointer{
    cursor: pointer;
}
// /* Importing Bootstrap SCSS file. */
// @import "~bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */
@import "../node_modules/ngx-bootstrap/datepicker/bs-datepicker";

