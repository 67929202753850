$black: #000;
$black-400: #404040;
$white: #fff;
$gray: #cccccc;
$green:#006C31;
$green-light: #00B050;
$green-dark:#51F119;
$yellow-light: #E33f00;
$yellow-dark: #FFFF00;

$module-color-success: #00B050;
$dark-module-color-success: #00EA6A;
$module-color-yellow: #C19200;

$primary: #00b0f0;
$warning: #E2AC00;
$dark-blue: #203764;
$dark-black:#1B1B1B;
$dark-black-100:#2A2A2A;
$dark-black-200:#252525;


// Color system

$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;

$grays: () !default;
$grays: map-merge(
  (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900
  ),
  $grays
);

$blue:    #007bff !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #28a745 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;

$colors: () !default;
$colors: map-merge(
  (
    "blue":       $blue,
    "indigo":     $indigo,
    "purple":     $purple,
    "pink":       $pink,
    "red":        $red,
    "orange":     $orange,
    "yellow":     $yellow,
    "green":      $green,
    "teal":       $teal,
    "cyan":       $cyan,
    "white":      $white,
    "gray":       $gray-600,
    "gray-dark":  $gray-800
  ),
  $colors
);

$primary:       $blue !default;
$secondary:     $gray-600 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-800 !default;
$pink:          #FF5B5B;
$success-light: #00B050;

$theme-colors: () !default;
$theme-colors: map-merge(
  (
    "primary":      $primary,
    "secondary":    $secondary,
    "success":      $success,
    "success-light":$success-light,
    "info":         $info,
    "warning":      $warning,
    "danger":       $danger,
    "pink":         $pink,
    "light":        $light,
    "dark":         $dark,
  ),
  $theme-colors
);


// Grid columns
//
// Set the number of columns and specify the width of the gutters.
$grid-gutter-width:           20px;

$border-radius:               0px;
$border-radius-lg:            0px;
$border-radius-sm:            0px;


$input-btn-font-size: 14px;
$modal-header-border-width: 0px!default;
$modal-footer-border-width: 0px!default;

/*----------------------------
  Light Mode Theme Color
------------------------------*/
// Page Loader Color
$page-loader-bg: rgba($black, 0.5);

// Header Color
$header-bg-color: $dark-blue;
$header-search-control-bg-color: #E7E5E6;
$header-search-border-color: $black;
$search-border-color: #CECECE;

// Calculatore Style
$cal-input-border-color:#161616;
$cal-input-text-color:$white;
$cal-button-text-color: $white;
$cal-button-bg-color: #262626;
$cal-button-hover-bg-color: #000000;
$cal-button-operator-bg-color: #262626;
$cal-button-operator-hover-bg-color: #161616;

/* Phone Style */
$phone-input-bg-color:#D9D9D9;
$phone-input-border-color: #D9D9D9;
$phone-button-bg-color: #595959;
$phone-button-border-color: #595959;

// Message Style
$msg-notification-bg-color: $white;
$msg-notification-divider-bg-color: #e7e7e7;
$msg-notification-item-border-color: #d7d7d7;
$msg-notification-item-hover-bg-color: #f2f2f2;
$msg-notification-item-date-color:#A6A6A6;
$msg-chat-bg-color: #f2f2f2;

// Menubar Color
$menubar-bg-color: #F2F2F2;

// Page Menu Action
$page-menu-action-btn-text-color:#3A3838;
$page-menu-action-btn-active-bg-color:#203764;
$page-menu-action-btn-active-text-color: $white;

$nav-menu-border-color: #808080;
$navbar-bg-color: #F2F2F2;
$nav-item-bg-color: $white;
$nav-item-text-color:#3A3838;
$navbar-border-color: #cccccc;

// Header User Dropdown
$navbar-dropdown-bg-color:$white;
$navbar-dropdown-text-color:#3A3838;
$navbar-dropdown-link-color:#3A3838;
$navbar-dropdown-border-color:#F2F2F2;

// Footer Color
$footer-bg-color: $dark-blue;
$footer-text-color: #cccccc;
$footer-border-color: #808080;

// Main Page Heading Color
$page-heading-bg-color: $white;
$page-heading-text-color: $dark-blue;
$page-heading-border-color: $gray;

// Login Form Color
$login-form-bg-color: #F2F2F2;
$login-form-label-color: $black;
$login-form-text-color: $black;
$login-form-control-bg-color: #F2F2F2;
$login-form-text-color: $black;
$login-form-heading-color: $black;

// Page Main Wrapper
$page-main-wrapper-bg-color:#BFBFBF;
$page-content-inner-bg-color: #F2F2F2;

// LMS Card Color
$lms-card-bg-color: $white;
$lms-card-text-color: #3A3838;
$lms-card-heading-color: $primary;
$lms-card-heading-size: 16px;
$lms-card-text-size: 14px;
$lms-card-padding: 12px 16px;

// Button Color
$btn-help-text-color: #3A3838;
$btn-help-text-size: 12px;

$btn-add-new-bg-color: #F2F2F2;
$btn-add-new-border-color: #cccccc;
$btn-file-success-bg-color: #00B050;


// Form Control Color
$form-control-bg-color: #F2F2F2;
$form-control-text-color: #3A3838;
$form-control-border-color: $primary;
$form-control-text-size: 14px;
$form-label-border-color: #888888;
$form-readonly-border-color: #888888;

$form-bg-color:  #F2F2F2;

// File Upload Color
$file-upload-bg-color: #F2F2F2;
$file-upload-text-color: #3A3838;
$file-upload-heading-size: 14px;

// Save Date Time
$save-datetime-text-size: 14px;

// Client Nav
$client-nav-border-color: #cccccc;
$client-nav-text-color: #3A3838;
$client-nav-active-color: #3A3838;

// Client Item
$client-item-bg-color:#E2E2E2;
$client-item-text-color:#333333;
$client-item-border-color:#E5E5E5;
$client-item-active-border-color: #38CAFF;
$client-item-active-bg-color:#ffffff;
$client-item-text-active:#00B050;
$client-item-text-inactive:#808080;
$client-item-text-terminated:#C00000;

// Notification Sidebar Nav
$notification-bg-color:$white;
$notification-nav-border-color: #cccccc;
$notification-nav-text-color: #3A3838;
$notification-nav-active-color: #3A3838;

// Category Listing
$category-item-bg-color:#404040;
$category-item-bg-color-light:#999999;
$category-link-color:#3A3838;
$category-link-active-color: $primary;
$category-link-inactive-color:#6D6C6C;

// Role List
$role-level-1-bg-color: #203764;
$role-level-2-bg-color: #757272;
$role-level-3-bg-color: #8D8686;
$role-level-4-bg-color: #ABA4A4;
$role-bg-danger-color: #C80000;

// Sidebar Detail (Applicant Detail)
$applicant-sidebar-bg-color:#F2F2F2;
$applicant-sidebar-border-color: #808080;
$applicant-card-bg-color: $white;
$applicant-sidebar-actions-bg-color: $white;
$applicant-sidebar-outbound-call-bg-color: $white;
$applicant-btn-outbound-call-bg-color:#FFF06A;
$applicant-btn-outbound-call-border-color:#272727;
$applicant-btn-outbound-call-text-color:#272727;
$applicant-sidebar-heading-bg-color:$white;
$applicant-sidebar-heading-text-color: #3A3838;
$applicant-sidebar-heading-border-color:$primary;
$applicant-sidebar-heading-border-success-color: #00EA6A;

// Reference card
$reference-card-bg-color:#f9f9f9;
$reference-card-border-color:#ededed;


// Table Style
$datatable-font-size:14px;
$datatable-header-text-color:$white;
$datatable-bg-odd-color:#f7f7f7;
$datatable-bg-even-color:$white;


// Version Style
$version-heading-color: #212529;
$version-card-padding: 4px 16px;
$version-card-bg-color: #F2F2F2;
$version-heading-size: 14px;
$version-card-text-color: #3A3838;
$version-card-text-size: 14px;

// notification email style
$notification-email-font-size: 14px;
$notification-email-space: 10px;
$notification-email-lineheight: 24px;
$notification-email-header-bg-color:$white;
$notification-email-body-bg-color:#f2f2f2;
$notification-info-bg-color:#cccccc;
$notification-email-border-color:$white;
$notification-email-border-active-color:#00EA6A;

// File Upload List Style
$file-name-bg-color: #f3f3f3;

// characteristic Style
$characteristic-bg-color: #e7e5e6;

/*----------------------------
  Dark Mode Theme Color
------------------------------*/
// Header Color
$dark-header-bg-color: $dark-black;

// Calculatore Style
$dark-cal-input-border-color:#161616;
$dark-cal-input-text-color:$white;
$dark-cal-button-text-color: $white;
$dark-cal-button-bg-color: #161616;
$dark-cal-button-hover-bg-color: #000000;
$dark-cal-button-operator-bg-color: #262626;
$dark-cal-button-operator-hover-bg-color: #161616;

/* Phone Style */
$dark-phone-input-bg-color:#D9D9D9;
$dark-phone-input-border-color: #D9D9D9;
$dark-phone-button-bg-color: #595959;
$dark-phone-button-border-color: #595959;

// Message Style
$dark-msg-notification-bg-color: #0D0D0D;
$dark-msg-notification-divider-bg-color: #404040;
$dark-msg-notification-item-border-color: #595959;
$dark-msg-notification-item-hover-bg-color: #595959;
$dark-msg-notification-item-date-color:#A6A6A6;
$dark-msg-chat-bg-color: #161616;

// Menubar Color
$dark-menubar-bg-color: $dark-black;

// Page Menu Action
$dark-page-menu-action-btn-text-color:$white;
$dark-page-menu-action-btn-active-bg-color:#565656;
$dark-page-menu-action-btn-active-text-color: $white;

// Navbar Color
$dark-navbar-bg-color: $dark-black;
$dark-nav-item-bg-color: #262626;
$dark-nav-item-text-color:$white;
$dark-navbar-border-color: #CCCCCC;

// Header User Dropdown
$dark-navbar-dropdown-bg-color:#404040;
$dark-navbar-dropdown-text-color:$white;
$dark-navbar-dropdown-link-color:$white;
$dark-navbar-dropdown-border-color:#808080;

// Footer Color
$dark-footer-bg-color: $dark-black-200;

// Main Page Heading Color
$dark-page-heading-bg-color: $dark-black-100;
$dark-page-heading-text-color: $white;
$dark-page-heading-border-color: #cccccc;

// Login Form Color
$dark-login-form-bg-color: #f2f2f2;
$dark-login-form-label-color: $black;
$dark-login-form-text-color: $black;
$dark-login-form-control-bg-color: #F2F2F2;
$dark-login-form-text-color: $black;
$dark-login-form-heading-color: $black;

// Page Main Wrapper
$dark-page-main-wrapper-bg-color:#565656;
$dark-page-content-inner-bg-color: #1b1b1b;

// LMS Card Color
$dark-lms-card-bg-color: #404040;
$dark-lms-card-bg-dark-color: #1B1B1B;
$dark-lms-card-text-color: $white;
$dark-lms-card-heading-color: #71DAFF;

// Button Color
$dark-btn-help-text-color: $white;
$dark-btn-add-new-bg-color: #404040;

// Form Control Color
$dark-form-control-bg-color: #3A3838;
$dark-form-control-text-color: $white;
$dark-form-bg-color:#262626;

// File Upload Color
$dark-file-upload-bg-color: #262626;
$dark-file-upload-text-color: $white;

// Client Nav
$dark-client-nav-border-color: #cccccc;
$dark-client-nav-text-color: $white;
$dark-client-nav-active-color: $white;

// Client Item
$dark-client-item-bg-color:#262626;
$dark-client-item-text-color:#ffffff;
$dark-client-item-border-color:#757171;
$dark-client-item-active-border-color: #38CAFF;
$dark-client-item-active-bg-color:#404040;
$dark-client-item-text-active:#00B050;
$dark-client-item-text-inactive:#808080;
$dark-client-item-text-terminated:#FB4949;

// Document Attach
$dark-document-preview-bg-color: #262626;
$dark-document-thumb-bg-color: #404040;

// Notification Sidebar Nav
$dark-notification-bg-color:#1b1b1b;
$dark-notification-nav-border-color: $white;
$dark-notification-nav-text-color: $white;
$dark-notification-nav-active-color: $white;

// Category Listing
$dark-category-link-color:$white;
$dark-category-link-inactive-color:#A6A6A6;

// Role List
$dark-role-level-1-bg-color: #858282;
$dark-role-level-2-bg-color: #4E4C4C;
$dark-role-level-3-bg-color: #2B2A2A;
$dark-role-level-4-bg-color: #1B1B1B;
$dark-role-bg-danger-color: #C80000;

// Sidebar Detail (Applicant Detail)
$dark-applicant-sidebar-bg-color:#1B1B1B;
$dark-applicant-sidebar-border-color: #cccccc;
$dark-applicant-card-bg-color:#262626;
$dark-applicant-sidebar-actions-bg-color: #262626;
$dark-applicant-sidebar-outbound-call-bg-color: #262626;
$dark-applicant-btn-outbound-call-bg-color:#616057;
$dark-applicant-btn-outbound-call-border-color:#FFFF00;
$dark-applicant-btn-outbound-call-text-color:#FFFF00;
$dark-applicant-sidebar-heading-bg-color:#262626;
$dark-applicant-sidebar-heading-text-color: $white;
$dark-applicant-sidebar-heading-border-color:$primary;
$dark-applicant-sidebar-heading-border-success-color: #00EA6A;

// Reference card
$dark-reference-card-bg-color:#4F4F4F;
$dark-reference-card-border-color:#666666;


// Datatable Style
$dark-datatable-bg-odd-color:#393939;
$dark-datatable-bg-even-color:#404040;

// Version style
$dark-version-heading-color: $white;
$dark-version-card-bg-color: #606060;
$dark-version-card-text-color: $white;
$dark-version-card-bg-dark-color: #1B1B1B;

// notification email style
$dark-notification-email-header-bg-color:#404040;
$dark-notification-email-body-bg-color:#393939;
$dark-notification-email-border-color:#404040;
$dark-notification-info-bg-color:#292929;

// File Upload List Style
$dark-file-name-bg-color: #1B1B1B;

// characteristic Style
$dark-characteristic-bg-color: #262626;
