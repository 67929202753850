[data-theme="dark"] {
  /* Login Header */
  .login-header,
  .page-header {
    background-color: $dark-header-bg-color;
  }

  /* Page Header Actions */
  .header-actions {
    .btn-icon {
      .icon {
        .icon-dark {
          display: inline-block;
        }
        .icon-light {
          display: none;
        }
      }
    }
  }

  .search-control {
    &::placeholder{
      color: #444444 !important;
    }
    &::-webkit-input-placeholder{
      color: #444444 !important;
    }
    &::-moz-placeholder{
      color: #444444 !important;
    }
  }

  /* Calculator */
  .calculator {
    .calculator-screen {
      background:$dark-cal-input-border-color;
      color: $dark-cal-input-text-color;
    }

    button {
      background-color: $dark-cal-button-bg-color;
      color: $dark-cal-button-text-color;
      &:hover {
        background-color:$dark-cal-button-hover-bg-color;
      }
      &.operator{
        background-color: $dark-cal-button-operator-bg-color;
        &:hover {
          background-color: $dark-cal-button-operator-hover-bg-color;
        }
      }
    }
  }
  /* Phone */
  .phone {
    .phone-screen {
      border: 1px solid $dark-phone-input-bg-color;
      background: $dark-phone-input-border-color;
      color: $black;
    }

    button {
      background-color: $dark-phone-button-bg-color;
      border: 1px solid $dark-phone-button-border-color;
      color: $white;
      &:hover {
        color: $yellow-dark;
        border-color: $yellow-dark;
      }
      &.action{
        background-color: $green-light;
        border-color: $green-light;
        color: $white;
        &:hover {
          background-color: darken($green-light, 5%);
          border-color: darken($green-light, 5%);
        }
      }
    }
  }

  /* Message Notification */
  .message-notification-divider{
    background: $dark-msg-notification-divider-bg-color;
    color: $white;
  }
  .message-notification-body,
  .message-notification-header{
    background: $dark-msg-notification-bg-color;
  }
  .message-notification-item{
    border-color: $dark-msg-notification-item-border-color;
    .date{
      color: $dark-msg-notification-item-date-color;
    }
    &.unread{
      border-color: $yellow-dark;
    }
    &:hover{
      background: $dark-msg-notification-item-hover-bg-color;
      &.unread{
        border-color: $yellow-dark;
      }
    }
  }
  #chat{
    background-color: $dark-msg-chat-bg-color;
  }


  /* Page Menu Block */
  .page-menubar {
    background-color: $dark-menubar-bg-color;
  }

  /* Page Menu Action */
.page-menu-actions {
  a {
    color: $dark-page-menu-action-btn-text-color;
    &.active{
      background:$dark-page-menu-action-btn-active-bg-color;
      color:$dark-page-menu-action-btn-active-text-color;
    }
  }
}


  /* Page Navbar */
  .page-navbar {
    background-color: $dark-navbar-bg-color;
    border-color: $dark-navbar-border-color;
    & > a {
      color: $dark-nav-item-text-color;
      background: $dark-nav-item-bg-color;
      border-left: 5px solid $dark-nav-item-bg-color;
      &:hover {
        color: $primary;
      }
      &.active {
        color: $primary;
        border-color: $primary;
      }
    }
  }
  .navbar-submenu {
    .card {
      border-color: $dark-navbar-border-color;
    }
    .card-body,
.accordion-body {
      background: $dark-nav-item-bg-color;
    }
    .collapse,
    .collapsing {
      border-color: $primary;
    }
.btn-link,
button.accordion-button {
      color: $dark-nav-item-text-color;
      background: $dark-nav-item-bg-color;
      border-color: $dark-nav-item-bg-color;
      background-image: url("~/src/assets/images/sidebar/right-arrow-dark.png");
      &[aria-expanded="true"] {
        border-color: $primary;
        background-image: url("~/src/assets/images/sidebar/bottom-arrow-dark.png");
      }
    }
  }
  .navbar-submenu {
    .module-menu{
  .btn-link,
button.accordion-button {
        &[aria-expanded="true"] {
          border-color: #00EA6A;
        }
      }
      .collapse,
      .collapsing {
        border-color: #00EA6A;
      }
    }
    .card.active{
  .btn-link,
button.accordion-button {
        border-color: $primary;
      }
      &.module-menu{
    .btn-link,
button.accordion-button {
          border-color: #00EA6A;
        }
      }
    }
  }
  .submenu-items {
    a {
      color: $dark-nav-item-text-color;
      &:hover {
        color: #71DAFF;
      }
      &.active {
        color: #71DAFF;
      }
    }
  }
  .module-menu{
    .submenu-items {
      a {
        &:hover {
          color: #00EA6A;
        }
        &.active {
          color: #00EA6A;
        }
      }
    }
  }
  .submenu-items {
    li[expandmenu] {
      & > a:before {
        background-image: url("~/src/assets/images/sidebar/right-arrow-dark.png");
      }
      &.active {
        & > a:before {
          background-image: url("~/src/assets/images/sidebar/bottom-arrow-dark.png");
        }
      }
    }
  }

  // User Navar Dropdown Profile
  .user-profile-dropdown{
    background-color: $dark-navbar-dropdown-bg-color;
    border-color: $dark-navbar-dropdown-border-color;
    color: $dark-nav-item-text-color;
    .dropdown-divider{
      border-color: $dark-navbar-dropdown-border-color;
    }
    .user-profile{
      h6,p{
        color: $dark-nav-item-text-color;
      }
    }
.btn-link,
button.accordion-button{
      color: $dark-navbar-dropdown-link-color;
    }
  }

  /* Light and Dark Icon Image  */
  .icon-light {
    display: none;
  }
  .icon-dark {
    display: inline-block;
  }

  /* Login Footer */
  .login-footer,
  .page-footer {
    background: $dark-footer-bg-color;
  }

  /* Main Page Heading */
  .main-page-heading {
    border-color: $dark-page-heading-border-color;
    background: $dark-page-heading-bg-color;
    .page-heading {
      color: $dark-page-heading-text-color;
    }
  }

  /* Login Form Wrapper */
  .login-form-wrapper {
    .login-form {
      background:$white;
      color: $dark-login-form-heading-color;
      .form-heading{
        color: $dark-login-form-heading-color;
      }
      .form-control {
        color: $form-control-text-color;
        background-color: $form-control-bg-color;
        border-color: $form-control-border-color;
      }
      .custom-control-label::before {
        background-color: $white;
        border: #adb5bd solid 1px;
      }
      .custom-checkbox {
        .custom-control-input:checked ~ .custom-control-label{
          &:before{
            background-color: $primary;
          }
        }
      }
    }
  }

  /* Loin Form Content */
  .login-form-content {
    background: $dark-login-form-bg-color;

  }
  .modal-dialog{
    .login-form-content{
      background: #0e0e0e;
    }
  }
  .modal-dialog {
    .modal-content {
      border: 6px solid #808080;
      background-color: #404040;
    }
  }

  .draggable-container {
    .draggable-modal-content{
        border: 6px solid #808080;
        background-color: #404040;
    }
    .modal-content {
        border: 0px !important;
        background-color: transparent !important;
    }
  }

  /* Main Page Wrapper */
  .main-page-wrapper {
    background-color: $dark-page-main-wrapper-bg-color;
  }
  .page-content-inner ,
  .page-content-sidebar{
    background-color: $dark-page-content-inner-bg-color;
  }

  // Search
  .search-control {
    background-color: $header-search-control-bg-color !important;
  }

  /* Lms Card Style */
  .lms-card {
    background: $dark-lms-card-bg-color;
    color: $dark-lms-card-text-color;
    &.lms-card-dark{
      background: $dark-lms-card-bg-dark-color;
    }
    .lms-card-heading {
      color: $dark-lms-card-heading-color;
    }
    .module-heading-success {
      color: $dark-module-color-success;
    }
    .module-heading-yellow {
      color: $module-color-yellow;
    }
  }

  /* Lms Module Card Style */
  .lms-module-card {
    background: $dark-lms-card-bg-color;
    color: $dark-lms-card-text-color;
  }

  // Button Style
  .btn-help-info {
    color: $dark-btn-help-text-color;
    &.is-hover {
      span {
        color: $dark-btn-help-text-color;
      }
      &:hover {
        span {
          color: $dark-btn-help-text-color;
        }
      }
    }
  }
  .btn-add-new,
  .btn-file-upload,
  .btn-duplicate,
  .btn-upload {
    background-color: $dark-btn-add-new-bg-color;
    &:hover{
      background-color: $primary;
      border-color: $primary;
      color: $white;
    }
    &.btn-add-new-success{
      img{
        background: #00ea6a;
      }
      &:hover{
        background-color: #00ea6a;
        border-color: #00ea6a;
      }
    }
    &.btn-add-new-warning {
      img {
        background: $warning;
      }
      &:hover {
        background-color: $warning;
        border-color:$warning;
      }
    }
    &.btn-add-new-danger {
      img {
        background: $danger;
      }
      &:hover {
        background-color: $danger;
        border-color:$danger;
      }
    }
    &.btn-duplicate-success,
    &.btn-upload-success {
      img {
        background: #00ea6a;
      }
      &:hover {
        background-color: #00ea6a;
        border-color: #00ea6a;
      }
    }
    &.btn-icon-success{
      img {
        background-color: $btn-file-success-bg-color;
      }
      &:hover {
        background-color: $btn-file-success-bg-color;
        border-color: $btn-file-success-bg-color;
      }
    }
  }
  .btn-login-client {
    background-color: $dark-btn-add-new-bg-color;
    img{
      background: #00ea6a;
    }
    &:hover{
      background-color: #00ea6a;
      border-color: #00ea6a;
    }
  }

  .btn-file-remove,
  .btn-delete {
    background-color: $dark-btn-add-new-bg-color;
    &:hover{
      background-color: $danger;
      border-color: $danger;
      color: $white;
    }
  }

  // Form Control Style
  .form-control {
    color: $dark-form-control-text-color;
    background-color: $dark-form-control-bg-color;
    &:not([readonly]),
    &:not([disabled]){
      border-color: $form-control-border-color;
    }
    &[readonly],
    &[disabled]{
      background-color: $dark-form-control-bg-color !important;
      border-color: $form-readonly-border-color !important;
    }
    &.show-bg{
      background-color: $dark-form-control-bg-color !important;
    }
  }

  // File Upload Block
  .file-upload-block{
    background-color: $dark-file-upload-bg-color;
    .file-upload-heading{
      color: $dark-file-upload-text-color;
    }
  }

  // Save Version Icon
  .btn-version-prev,
  .btn-version-next{
    img{
      filter: invert(0);
    }
  }

  .img-invert{
    filter: invert(0);
  }

  // Client Nav
  .client-nav,
  .user-list-nav{
    border-color: $dark-client-nav-border-color;
    .nav-item{
      color: $dark-client-nav-text-color;
      &.active{
        span{
          border-color: $dark-client-nav-active-color;
        }
      }
    }
  }

  // Client Item
  .client-item,
  .user-list-item{
    background: $dark-client-item-bg-color;
    border-color: $dark-client-item-border-color;
    &:hover{
      background-color: $dark-client-item-active-bg-color;
    }
    &.active{
      background-color: $dark-client-item-active-bg-color;
      border: 2px solid $dark-client-item-active-border-color;
    }
    .client-name,
    .user-name {
      color: $dark-client-item-text-color;
    }
    .client-description,
    .user-description {
      color: $dark-client-item-text-color;
    }
    .client-status{
      &.active{
        color: $dark-client-item-text-active;
      }
      &.terminated{
        color: $dark-client-item-text-terminated;
      }
      &.inactive{
        color: $dark-client-item-text-inactive;
      }
    }
    .user-active-status{
      color: $dark-client-item-text-color;
    }
  }

  // Document Upload Preview
  .document-upload-preview {
    background-color: $dark-document-preview-bg-color;
  }
  .document-upload{
    background-color: $dark-document-thumb-bg-color;
  }

  // Notification Sidebar
  .lms-notification-sidebar,
  .lms-datawarehouse-sidebar {
    .lms-notification-sidebar-content{
      background-color: $dark-notification-bg-color;
    }
    .card{
      background-color: transparent;
    }
  }

   // Notification Sidebar
   .lms-role-sidebar{
     .lms-role-sidebar-content{
       background-color: $dark-notification-bg-color;
     }
     .card{
       background-color: transparent;
     }
   }
  // Notification Sidebar Nav
  .lms-notification-list-nav,
  .lms-message-list-nav {
    border-color: $dark-notification-nav-border-color;
    .nav-item{
      color: $dark-notification-nav-text-color;
      &.active{
        span{
          border-color: $dark-notification-nav-active-color;
        }
      }
    }
  }
  // Category Items
  .category-items a {
    color: $dark-category-link-color;
    &.inactive{
      color: $dark-category-link-inactive-color;
    }
  }

  // Category Listing
  .category-listing {
    &.card-header-bg-transparent{
  .btn-link,
button.accordion-button{
        color: $white;
        background-image: url("~/src/assets/images/sidebar/right-arrow-dark.png");
        &[aria-expanded="true"] {
          background-image: url("~/src/assets/images/sidebar/bottom-arrow-dark.png");
        }
      }
    }
  }

  // Role Listing
  .roles-table{
    .level-1{
      & > tr{
        background:$dark-role-level-1-bg-color;
      }
    }
    .level-2{
      & > tr{
        background:$dark-role-level-2-bg-color
      }
    }
    .level-3{
      & > tr{
        background: $dark-role-level-3-bg-color;
      }
    }
    .level-4{
      & > tr{
        background:$dark-role-level-4-bg-color;
      }
    }
    .bg-danger{
      background:$dark-role-bg-danger-color;
    }
  }

  // Applicant Sidebar
  .applicant-sidebar{
    background-color: $dark-applicant-sidebar-bg-color;
    border-color: $dark-applicant-sidebar-border-color;
    .card,
    .accordion-item{
      background-color: $dark-applicant-card-bg-color;;
      .card,
      .accordion-item{
        .card-header,
        .accordion-header{
          background-color: #1B1B1B;
          color: #ffffff;
          border: none;
      .btn-link,
      button.accordion-button{
            background-color: transparent;
            width: auto;
            background-image: url("~/src/assets/images/sidebar/right-arrow-dark.png");
            &[aria-expanded="true"] {
              background-image: url("~/src/assets/images/sidebar/bottom-arrow-dark.png");
            }
          }
        }
        .card-body,
        .accordion-body{
          background-color: #0d0d0d;//#000000;
        }
      }
    }
    .card-header,
    .accordion-header{
  .btn-link,
  button.accordion-button{
        background-color: $success;
        color: $white;
      }
    }
    a{
      color: $white;
    }
    .sidebar-actions{
      background:$dark-applicant-sidebar-actions-bg-color;
    }
    .sidebar-outbound-call{
      background:$dark-applicant-sidebar-outbound-call-bg-color;
    }
    .btn-outbound-call{
      background: $dark-applicant-btn-outbound-call-bg-color;
      border-color: $dark-applicant-btn-outbound-call-border-color;
      color: $dark-applicant-btn-outbound-call-text-color;
      &:hover{
        background:darken($dark-applicant-btn-outbound-call-bg-color, 10);
      }
    }
  }

  // Module Sidebar Heading
  .module-sidebar-heading{
    color: $dark-applicant-sidebar-heading-text-color;
    background: $dark-applicant-sidebar-heading-bg-color;
    border-color: $dark-applicant-sidebar-heading-border-color;
    &.module-success{
      border-color: $dark-applicant-sidebar-heading-border-success-color;
    }
  }

  // Module NavTabs
  .module-tabs.nav-tabs {
    .nav-link {
      border-color: transparent transparent $module-color-success transparent;
      font-size: 14px;
      color: $white;
      background: #565656;
    }
    .nav-link.active,
    .nav-item.show .nav-link {
      color: $white;
      background-color: #565656;
      border-color: $module-color-success $module-color-success #565656;
      position: relative;
      z-index: 2;
    }
    &.module-tabs-yellow{
      .nav-link {
        border-color: transparent transparent $module-color-yellow transparent;
      }
      .nav-link.active,
      .nav-item.show .nav-link {
        border-color: $module-color-yellow $module-color-yellow #565656;
      }
    }
  }

  // Module Button With Shadow
  .module-btn-shadow {
    box-shadow: 4px 4px 0px 1px #090909 !important;
    color: $white;
    &:hover{
      color: $white;
    }
  }

  .module-msg-description{
    background-color:#302F2F;
  }

  .btn-module-graph{
    background-image: url("~/src/assets/images/sidebar/right-arrow-dark.png");
    &[aria-expanded="true"] {
      background-image: url("~/src/assets/images/sidebar/bottom-arrow-dark.png");
    }
  }

  // Reference Card
  .reference-card{
    background: $dark-reference-card-bg-color;
    border-color: $dark-reference-card-border-color;
  }

   /* Lms Version Card Style */
  .lms-version-card {
    background: $dark-version-card-bg-color;
    color: $dark-version-card-text-color;
    &.lms-version-dark{
      background: $dark-version-card-bg-dark-color;
    }
    .lms-version-heading {
      color: $dark-version-heading-color;
    }
  }

  // Notification Email and Mail Listing
  .notification-email-list{
    .notification-email-item{
      border-color: $dark-notification-email-border-color;
      .notification-email-header{
        background-color: $dark-notification-email-header-bg-color;
      }
      .notification-email-body{
        background-color:$dark-notification-email-body-bg-color;
        .notification-bg-dark{
          background-color:$dark-notification-info-bg-color;
        }
      }
      &:hover {
        border-color: $notification-email-border-active-color;
      }
      &.active,
      &.selected {
        border-color: $notification-email-border-active-color;
      }
    }
  }

  /* Modal Style */
  .modal-title{
    &.modal-title-success{
      color: $dark-module-color-success;
    }
  }

  // File Upload List Style

  .file-upload-list{
    .file-name{
      background: $dark-file-name-bg-color;
    }
  }

  // characteristic list style
  .characteristic-container,
  .assignment-container{
    background: $dark-characteristic-bg-color;
  }

  .breakdown-progress{
    .progress-bar{
      span{
        color: #ffffff;
      }
    }
  }

}

[data-theme=dark] .notification-email-wrapper{
    background-color: #1B1B1B;
}
.main-left-menu .accordion-item {
  border-bottom: 1px solid #cccccc;
}

ngb-modal-backdrop.modal-backdrop.fade.show {
  z-index: 0 !important;
}