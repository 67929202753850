// Col
.col-30{
  max-width: 30px;
}
.col-50{
  max-width: 50px;
}
.col-60{
  max-width: 60px;
}
.col-label{
  width: 33.33%;
  flex: 0 0 33.33%;
}
// Button
.btn-150{
  min-width: 150px;
}
.btn-auto{
  width: auto;
}
.btn-full{
  width: 100%;
}
@include media-breakpoint-up(sm){
  .btn-md-auto{
    width: auto;
  }
  .btn-md-full{
    width: 100%;
  }
}
@include media-breakpoint-up(md){
  .btn-md-auto{
    width: auto;
  }
  .btn-md-full{
    width: 100%;
  }
}
@include media-breakpoint-up(lg){
  .btn-lg-auto{
    width: auto;
  }
  .btn-lg-full{
    width: 100%;
  }
}
@include media-breakpoint-up(xl){
  .btn-xl-auto{
    width: auto;
  }
  .btn-xl-full{
    width: 100%;
  }
}
.text-underline{
  text-decoration: underline !important;
  &:hover{
    text-decoration: none !important;
  }
}
.border-2{
  border-width: 2px !important;
}
.border-dashed{
  border:1px dashed $white;
}
.border-primary{
  border-color: $primary !important;
  [data-theme=dark] &{
    border-color: $primary !important;
  }
}
.border-light{
  border-color: $white !important;
  [data-theme=dark] &{
    border-color: $white !important;
  }
}
@media screen and (min-width:992px) {
  .border-lg-left{
    border-left: 1px solid $gray;
    [data-theme="dark"] &{
      border-color: #595959;
    }
  }
}
.help-text{
  font-size: 12px;
  &.help-text-light{
    color:#BFBFBF !important;
  }
}
.preview-top-divider{
  margin-left: -20px;
  margin-right: -20px;
}
.lms-text-dark{
  color: #3A3838 !important;
  [data-theme="dark"] &{
    color:$white !important;
  }
}
.lms-text-light-pink{
  color: #D46666;
  [data-theme="dark"] &{
    color: #DD9191;
  }
}

.lms-text-green{
  color: #145e01;;
  [data-theme="dark"] &{
    color: #145e01;;
  }
}
.lms-text-light-purple{
  color: #894db4 !important;
  [data-theme="dark"] &{
    color: #DFC9EF !important;
  }
}
.lms-text-phone-color{
  color: #C19200 !important;
  [data-theme="dark"] &{
    color: #FDD400 !important;
  }
}

.lms-text-orange-color{
  color: #C19200 !important;
  [data-theme="dark"] &{
    color: #FDD400 !important;
  }
}

.bg-dark-blue{
  background-color: #0070C0 !important;
}

.bg-form-content{
  background: $form-bg-color;
  [data-theme="dark"] &{
    background: $dark-form-bg-color;
  }
}
.text-success-light{
  color: $green-light !important;
  [data-theme="dark"] &{
    color: $green-dark !important;
  }
}
.text-yellow{
  color: $yellow-light !important;
  [data-theme="dark"] &{
    color: $yellow-dark !important;
  }
}
.border-yellow{
  border-color: $yellow-light !important;
  [data-theme="dark"] &{
    border-color: $yellow-dark !important;
  }
}

.text-error{
  color: #FF5B5B !important;
  [data-theme="dark"] &{
    color: #FF5B5B !important;
  }
}
.text-imported{
  color: #00EA6A !important;
  [data-theme="dark"] &{
    color: #00EA6A !important;
  }
}

.cursor-pointer-ss{
  cursor: pointer;
}
.bg-note-info{
  background:rgb(233,231,231);
  [data-theme="dark"] &{
    background:#3A3838;
  }

}
.underline-text{
    text-decoration: underline;
}
.table-align-middle th{
  vertical-align: middle !important;
}
