.form-control{
  margin: 0;
  padding: 5px 5px;
  border-width: 0px 0px 1px 0px;
  color: $form-control-text-color;
  background-color: $form-control-bg-color;
  font-size: $form-control-text-size;
  &:not([readonly]),
  &:not([disabled]) {
    border-color: $form-control-border-color;
  }
  &[readonly],
  &[disabled] {
    background-color: $form-control-bg-color !important;
    border-color: $form-readonly-border-color !important;
  }
  &.form-control-date{
    pointer-events: none;
  }
}
div.form-control {
  background-color: transparent !important;
  border-color: $form-readonly-border-color !important;
  &.show-bg {
    background-color: $form-control-bg-color !important;
  }
}
.form-column-border-right {
  border-right: 1px solid rgba(#888888, 0.5);
}
.form-border-success {
  .form-control {
    &:not([readonly]),
    &:not([disabled]) {
      border-color: $dark-module-color-success;
    }
  }
}
.form-border-yellow {
  .form-control {
    &:not([readonly]),
    &:not([disabled]) {
      border-color: $module-color-yellow;
    }
  }
}
.card-form-content {
  .form-group {
    margin-bottom: 5px;
  }
  &.card-form-success {
    .form-control {
      &:not([readonly]),
      &:not([disabled]) {
        border-color: $dark-module-color-success;
      }
    }
    .ngb-dp-weekday,
.btn-link,
button.accordion-button{
      color: $dark-module-color-success;
    }
    .bg-primary{
      background: $dark-module-color-success !important;
    }
  }
  &.card-form-yellow {
    .form-control {
      &:not([readonly]),
      &:not([disabled]) {
        border-color: $module-color-yellow;
      }
    }
    .ngb-dp-weekday,
.btn-link,
button.accordion-button{
      color: $module-color-yellow;
    }
    .bg-primary{
      background: $module-color-yellow !important;
    }
  }
}
.field-required:after {
  content: "*";
  color: $danger;
  margin-left: 2px;
  [data-theme="dark"] & {
    color: $white;
  }
}
.form-group label,
.form-group-label {
  font-size: $form-control-text-size;
  margin-bottom: 5px;
}
.card-form-content:not(.form-vertical) {
  .form-group-label {
    margin-bottom: 0px !important;
    padding-top: 5px;
    &:before {
      content: "";
      position: absolute;
      left: 5px;
      width: 100%;
      // height: 1px;
      // background: $form-label-border-color;
      border-bottom: 1px solid #888;
      top: 30px;
    }
  }
  .position-relative{
    .form-group-label {
      &:before{
        left: 0px;
      }
    }
    .payment-code-check-round, .payment-method-check-round{
      position: absolute;
      right: 12px;
      top: 5px;
    }
  }
}

.card-form-table {
  > .row {
    border-bottom: 1px solid $form-label-border-color;
    &:first-child {
      border-top: 1px solid $form-label-border-color;
    }
    & > .col {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
}

select {
  appearance: none;
  -webkit-appearance: none;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAGCAYAAAD68A/GAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABmSURBVHgBbYzBDYAgDEWtbkBYAdYSJxM34uSBITh7q/2IBkmbNGnff/mL9z4ZY65SSpqUcc4Fa22c5U5EtANoEjI4FUhrlOVexg2GDD+9QQMrM281eJqOnHP4ib3c3k9SR+QTO/IbsWMjc18pVNAAAAAASUVORK5CYII=");
  background-repeat: no-repeat;
  background-position: right 10px center;
  padding-right: 30px !important;
  [data-theme="dark"] & {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAAHCAYAAADebrddAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADHSURBVHgBfU+9CoJQFL4F2RXSpaVafYHoRXqOaG6tsbGhGnuBu/YOQSBOgqSGoohXUC8tDpfMzl3iRtIHB87hfHw/CAEsy5o0TdNBLTBNc/w5bNseFUVxS9P06HleXyYmSbLO85y6rjsVdzeKIsY5P2OMF7qu74IgwOIRhuFSVdVNXddXx3HusroCKvuyLJ9xHJ9831/BzimlBIjDn2yEECXLsgNj7CUG7C8gMpA5X6VEZk3TtlB2VlXV3DCMB/oHaN9rtQa8Ac7ZcB8hWVvNAAAAAElFTkSuQmCC");
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

::-webkit-inner-spin-button,
::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.text-count {
  font-size: 12px;
  display: block;
  text-align: right;
}

// Document Upload
.document-upload {
  max-width: 104px;
  height: 104px;
  padding: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  cursor: pointer;
  background: $gray;
  $document-border-color: #888888;
  border: 1px dashed $document-border-color;
  margin: 0px;
  .document-upload-input {
    display: none;
  }
  .document-upload-icon {
    max-width: 38px;
    height: auto;
  }
}

// Document Upload Preview
.document-upload-preview {
  max-width: 104px;
  height: 104px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 500;
  background: $primary;
  border: 1px solid $primary;
  position: relative;
  color: $white;
  span {
    font-size: 12px;
    word-break: break-all;
  }
  a {
    color: inherit !important;
  }
  .btn-remove {
    position: absolute;
    right: -12px;
    top: -12px;
  }
}
.document-upload-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0px -5px;
  .document-item {
    padding: 0px 5px;
  }
}

@mixin can-toggle-branding(
  $can-toggle-off-color: #00b050,
  $can-toggle-on-color: #00b050,
  $can-toggle-inactive-text: $white,
  $can-toggle-transition: cubic-bezier(0, 1, 0.5, 1)
) {
  input[type="checkbox"] {
    &[disabled] ~ .can-toggle__switch {
      color: rgba($can-toggle-off-color, 0.5);
    }

    &:focus ~ .can-toggle__switch,
    &:hover ~ .can-toggle__switch {
      &:after {
        background-color: $can-toggle-off-color;
        color: $white;
      }
    }
    &:hover ~ .can-toggle__switch {
      color: darken($can-toggle-off-color, 5%);
    }
    &:checked {
      ~ .can-toggle__switch {
        &:after {
          background-color: $can-toggle-on-color;
          color: $white;
        }
        &:hover {
          color: darken($can-toggle-on-color, 3%);
        }
      }

      &:focus,
      &:hover {
        ~ .can-toggle__switch {
          &:after {
            background-color: $can-toggle-on-color;
            color: $white;
          }
        }
      }
    }
  }

  .can-toggle__switch {
    transition: background-color 0.3s $can-toggle-transition;
    background: lighten($can-toggle-off-color, 5%);
    .can-toggle__label-text {
      flex: 1;
    }
    &:before {
      color: $can-toggle-inactive-text;
    }
    &:after {
      // Autoprefixer choked here, so making the prefixes explicit
      -webkit-transition: -webkit-transform 0.3s $can-toggle-transition;
      transition: transform 0.3s $can-toggle-transition;
      background-color: $can-toggle-on-color;
      color: $white;
    }
  }
}

@mixin can-toggle-appearance(
  $can-toggle-width: 134px,
  $can-toggle-height: 26px,
  $can-toggle-border-radius: 4px,
  $can-toggle-offset: 2px,
  $can-toggle-label-font-size: 14px,
  $can-toggle-switch-font-size: 12px,
  $can-toggle-shadow: 0 3px 3px rgba(black, 0.4)
) {
  $can-toggle-switch-width: $can-toggle-width/2;
  width: $can-toggle-width;
  background: #1b1b1b8a;
  border-radius: 24px;
  line-height: 0px;
  [data-theme="dark"] & {
    background: #000;
  }
  label {
    margin-bottom: 0px;
  }
  input[type="checkbox"] {
    &:checked {
      ~ .can-toggle__switch {
        &:after {
          transform: translate3d(
            $can-toggle-width - ($can-toggle-switch-width + $can-toggle-offset),
            0,
            0
          );
        }
      }
    }
  }

  .can-toggle__switch {
    font-size: $can-toggle-label-font-size;
    height: $can-toggle-height;
    flex: 0 0 $can-toggle-width;
    border-radius: $can-toggle-border-radius;

    &:before {
      left: $can-toggle-width/2;
      font-size: $can-toggle-switch-font-size;
      line-height: $can-toggle-height;
      width: $can-toggle-width/2;
      padding: 0 12px;
    }

    &:after {
      top: $can-toggle-offset;
      left: $can-toggle-offset;
      border-radius: $can-toggle-border-radius * 5;
      width: $can-toggle-switch-width - $can-toggle-offset;
      line-height: $can-toggle-height - ($can-toggle-offset * 2);
      font-size: $can-toggle-switch-font-size;
    }
  }
}

.can-toggle {
  position: relative;
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;

    &[disabled] ~ label {
      pointer-events: none;
      .can-toggle__switch {
        opacity: 0.4;
      }
    }

    &:checked {
      ~ .can-toggle__switch {
        &:before {
          content: attr(data-unchecked);
          left: 0;
        }

        &:after {
          content: attr(data-checked);
        }
      }
    }
  }

  .can-toggle__switch {
    user-select: none;
    position: relative;
    display: flex;
    align-items: center;
    .can-toggle__label-text {
      flex: 1;
      padding-left: 32px;
    }
    &:before {
      content: attr(data-checked);
      position: absolute;
      top: 0;
      text-transform: uppercase;
      text-align: center;
    }

    &:after {
      content: attr(data-unchecked);
      position: absolute;
      z-index: 5;
      text-transform: uppercase;
      text-align: center;
      background: $white;
      transform: translate3d(0, 0, 0);
    }
    &.text-capitalize{
      &:before,
      &:after {
        text-transform: capitalize;
      }
    }
  }

  @include can-toggle-branding;
  @include can-toggle-appearance;

  &.can-toggle--size-small {
    @include can-toggle-appearance(
      90px,
      // Toggle
      26px,
      // Toggle h
      4px,
      // Toggle border r
      1px,
      // Offset (distance btw switch and
      13px,
      // Label font
      10px,
      // Switch font
      0 2px 2px rgba(black, 0.4)
    ); // Switch shadow on hover/
  }
}

.checkbox-round {
  label {
    position: relative;
  }
  span {
    background-color: #808080;
    border-radius: 50%;
    cursor: pointer;
    height: 20px;
    left: 0;
    position: absolute;
    top: 0;
    width: 20px;
    &:after {
      border: 2px solid #3A3838;
      border-top: none;
      border-right: none;
      content: "";
      height: 6px;
      left: 50%;
      opacity: 0;
      position: absolute;
      top: 50%;
      transform: translate3d(-50%, -50%, 0) rotate(-45deg);
      width: 12px;
    }
  }
  input[type="checkbox"] {
    visibility: hidden;
    &:checked + span {
      background-color: $dark-module-color-success;
      border-color: $dark-module-color-success;
    }
    &:checked + span:after {
      opacity: 1;
    }
  }
}


input[type="color"]{
  background-color: transparent;
  border: 0;
  width: 27px;
  pointer-events: none;
  &::-webkit-color-swatch-wrapper{
    padding: 0px;
  }
}

button.btn.calendar {
  right: 0px;
  top: 0px;
  position: absolute;
  padding: 2px 8px;
}
.ngb-dp-months {
  background: #fff;
}