body {
  overflow-x: hidden;
  // font-family: 'Open Sans', sans-serif;
  font-family: 'Roboto', sans-serif;
  letter-spacing:0.03em;
}

*,
:focus {
  outline: none;
  box-shadow: none !important;
}

*:focus,
*:focus,
*:active:focus,
*:focus {
  outline: none !important;
  box-shadow: none !important;
}


.theme-switch {
  background: $white;
  padding: 6px;
  right: 0px;
  top: 61px;
  position: fixed;
  z-index: 1024;

  .btn-secondary {
    display: none;
  }
}

.gutters-5{
  margin-left: -5px;
  margin-right: -5px;
  &>*{
    padding-left: 5px;
    padding-right: 5px;
  }
}
.gutters-8{
  margin-left: -8px;
  margin-right: -8px;
  &>*{
    padding-left: 8px;
    padding-right: 8px;
  }
}
.gutters-10{
  margin-left: -10px;
  margin-right: -10px;
  &>*{
    padding-left: 10px;
    padding-right: 10px;
  }
}

.page-loader {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  background: $page-loader-bg;
}

// CEK Editor
.ck.ck-editor{
  color:$black-400;
}
.ck-editor__editable_inline {
  min-height: 865px;
}
.ck.ck-toolbar,
.ck-editor__editable_inline{
  border: none !important;
}

a,
a label {
    cursor: pointer;
}

/* Error Main Wrapper */
$error-main-wrapper-bg-color: #f5f5f5;
$dark-error-main-wrapper-bg-color: #262626;
$error-title-color: #212529;
.error-main-wrapper {
  background-color: $error-main-wrapper-bg-color;
  .error-inner-content {
    min-height: calc(100vh - 160px);
    display: flex;
    align-items: center;
  }
}
.error-title{
  font-size: 96px;
}
.error-sub-title{
  font-size: 36px;
}
.error-description{
  font-size: 24px;
}
[data-theme="dark"]{
  .error-main-wrapper {
    background-color: $dark-error-main-wrapper-bg-color;
    color: $white;
  }
}

// No Data Found
.no-data-found{
  padding: 15px;
  font-size: inherit;
  text-align: center;
  font-size: 14px;
}

// Rouned Tabs
.nav-tabs-rounded {
  background-color: #1b1b1b8a;
  border-radius: 30px;
  overflow: hidden;
  justify-content: space-between;
  border: none;
  padding: 2px 2px 3px 2px !important;
  line-height: normal;
  .nav-link {
    padding: 5px 20px;
    font-size: 12px;
    border-radius: 30px;
    border: none;
    color: #fff;
    border: none;
    &.active {
      background: #00b050;
      color: #fff;
    }
  }
}

[data-theme="dark"]{
  .nav-tabs-rounded {
    background: #1b1b1b;
    .nav-link {
      color: #fff;
      &.active {
        background: #00b050;
        color: #fff;
      }
    }
  }
}

.chart-container{
  color: #000000;
  text-align: center;
}
.login-modal-show{
  .body-wrapper{
    -webkit-filter: blur(5px);
    filter: blur(5px);
  }
}


// sticky header
.body-wrapper{
    padding-top: 58px;
    .header-block{
        position: fixed;
        top: 0px;
        z-index: 100;
        width: 100%;
    }
   
    .show-login-as-client{
        // padding-top: 102px;
        .header-block{
            top: 40px;
        }
        .fixed-height-block {
            height: 40px;
        }
        .sticky-header-queue {
            top: 100px !important;
        }
        .fixed-height-block {
            height: 40px;
        }
    }
    .show-login-as-client2{
        // padding-top: 145px;
        .header-block{
            top: 75px;
        }
        .fixed-height-block {
            height: 75px;
        }
        .sticky-header-queue {
            top: 135px !important;
        }
        .sticky-header-wrapper {
            height:82px !important;
        }
    }
}
.row.flex-nowrap.no-gutters {
  margin-top:5px;
}
.sticky-header-wrapper {
  height:82px !important;
}


.run-live-report {
  .nav-tabs-rounded .nav-link.active{
  background-color: #00B0F0;
  border-radius: 0 !important;
  border-color: #00b0f0 !important;
  }
  .nav-tabs-rounded{
    background-color: transparent;
    border-radius: 0 !important;
  }
  .nav-link{
    line-height: 16px;
  }
  .nav-link,
  .nav-link:hover {
    // width: 25%;
    text-align: center;
    border: 1px solid;
    border-color: #cccccc !important;
    border-radius: 0;
  }
}
