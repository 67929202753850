/* Login Header */
.login-header,
.page-header {
  padding: 10px 15px;
  background-color: $header-bg-color;
  .date-time span {
    font-size: 14px;
  }
  .header {
    display: flex;
    align-items: center;
  }
  // border-bottom:1px solid;
}
.login-header{
  border-bottom:1px solid;
}
.login-footer{
  border-top: 1px solid $footer-border-color;
}
/* Login Footer */
.login-footer,
.page-footer {
  background: $footer-bg-color;
  color: $footer-text-color;
  font-size: 12px;
  padding: 10px 15px;
}
// .page-footer {
//   border-top: 1px solid $footer-border-color;
// }
/* Page Header Search */
.header-search {
  max-width: 437px;
  display: inline-block;
  width: 100%;
  margin-left: 82px;
  .search-control {
    background-color: $header-search-control-bg-color !important;
    border: 1px solid $black;
  }
}
.search-control {
  height: 30px;
  padding-left: 40px;
  background-image: url("~/src/assets/images/header/search-icon.png");
  background-repeat: no-repeat;
  background-position: left 11px center;
  background-color: $header-search-control-bg-color !important;
  border: 1px solid $search-border-color;
  color: $dark-black !important;
  &::placeholder {
    color: #444444 !important;
  }
  &::-webkit-input-placeholder {
    color: #444444 !important;
  }
  &::-moz-placeholder {
    color: #444444 !important;
  }
}

/* Page Header Actions */
.header-actions {
  margin: 0px -10px;
  display: flex;
  align-items: center;
  .btn-icon {
    margin: 0px 10px;
    display: inline-flex;
    padding: 0px;
    .icon {
      width: 40px;
      height: 40px;
      display: inline-block;
      img {
        max-width: 100%;
      }
      .icon-dark {
        display: none;
      }
      .icon-light {
        display: inline-block;
      }
      &.icon-cirlce {
        border-radius: 50%;
        overflow: hidden;
      }
    }
    &.user {
      .icon {
        border: 2px solid $primary;
      }
    }
  }
}

/* Calculator */
.calculator {
  .calculator-screen {
    width: 100%;
    font-size: 28px;
    height: 48px;
    padding: 5px 10px;
    border: none;
    background: $cal-input-border-color;
    color: $cal-input-text-color;
    text-align: right;
  }

  button {
    height: 48px;
    border: none;
    font-size: 18px;
    background-color: $cal-button-bg-color;
    color: $cal-button-text-color;
    &:hover {
      background-color: $cal-button-hover-bg-color;
    }
    &.operator{
      background-color: $cal-button-operator-bg-color;
      &:hover {
        background-color: $cal-button-operator-hover-bg-color;
      }
    }
    &.equal-sign {
      background-color: $primary !important;
      &:hover {
        background-color: darken($primary, 5%) !important;
      }
    }
  }

  .all-clear {
    background-color: #f0595f;
    color: #fff;
    &:hover {
      background-color: #f17377;
    }
  }

  .equal-sign {
    height: 100%;
    grid-area: 5/4/7/5;
  }

  .zero-sign{
    height: 100%;
    grid-area: 6/3/7/1;
  }

  .plus-sign {
    height: 100%;
    grid-area: 3/4/5/5;
  }

  .calculator-keys {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    margin-top: 10px;
  }
}

/* Phone */
.phone {
  .phone-screen {
    width: 100%;
    font-size: 14px;
    height: 100%;
    padding: 5px 10px;
    border: 1px solid $phone-input-bg-color;
    background: $phone-input-border-color;
    color: $black;
    text-align: center;
    grid-area: 1/1/1/3;
  }

  button {
    height: 36px;
    border: none;
    font-size: 14px;
    background-color: $phone-button-bg-color;
    border: 1px solid $phone-button-border-color;
    color: $white;
    &:hover {
      color: $yellow-dark;
      border-color: $yellow-dark;
    }
    &.action{
      background-color: $green-light;
      border-color: $green-light;
      color: $white;
      &:hover {
        background-color: darken($green-light, 5%);
        border-color: darken($green-light, 5%);
      }
    }
  }

  .phone-keys {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
  }
}
.calculator-modal-header,
.message-modal-header,
.phone-modal-header{
  .icon-cirlce{
    margin-left: -15px;
  }
}

/* Message Notification */
.message-notification-header{
  padding-top: 10px;
  .lms-message-list-nav,
  .search {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.message-notification-divider{
  margin-bottom: 4px;
  background: $msg-notification-divider-bg-color;
  color: $dark;
  text-align: center;
  font-size: 12px;
}
.message-notification-body,
.message-notification-header{
  background: $msg-notification-bg-color;
}
.message-notification-body{
  max-height: 250px;
  overflow-y: auto;
}
.message-notification-list{
  padding: 4px 10px;
}
.message-notification-item{
  border: 1px solid $msg-notification-item-border-color;
  font-size: 11px;
  padding: 10px;
  margin-bottom: 10px;
  .title{
    font-size: 12px;
  }
  .message{
    font-size: 11px;
  }
  .date{
    font-size: 10px;
    color: $msg-notification-item-date-color;
  }
  &.unread{
    border-color: $yellow-light;
  }
  &:hover{
    background: $msg-notification-item-hover-bg-color;
    border-color: $primary;
    &.unread{
      &:hover{
        border-color: $yellow-light;
      }
    }
  }
  .header{
    display: flex;
    align-items: center;
  }
}

/* Login Footer */
.login-footer,
.page-footer {
  background: $footer-bg-color;
  color: $footer-text-color;
  font-size: 12px;
  padding: 10px 15px;
}
// .page-footer{
//   border-top:1px solid $footer-border-color;
// }
/* Page Header Search */
.header-search {
  max-width: 437px;
  display: inline-block;
  width: 100%;
  margin-left: 82px;
  .search-control {
    background-color: $header-search-control-bg-color !important;
    border: 1px solid $black;
  }
}
.search-control {
  height: 30px;
  padding-left: 40px;
  background-image: url("~/src/assets/images/header/search-icon.png");
  background-repeat: no-repeat;
  background-position: left 11px center;
  background-color: $header-search-control-bg-color !important;
  border: 1px solid $search-border-color;
  color: $dark-black !important;
  &::placeholder{
    color: #444444 !important;
  }
  &::-webkit-input-placeholder{
    color: #444444 !important;
  }
  &::-moz-placeholder{
    color: #444444 !important;
  }
}

/* Page Header Actions */
.header-actions {
  margin: 0px -10px;
  display: flex;
  align-items: center;
  .btn-icon {
    margin: 0px 10px;
    display: inline-flex;
    .icon {
      width: 40px;
      height: 40px;
      display: inline-block;
      img {
        max-width: 100%;
      }
      .icon-dark {
        display: none;
      }
      .icon-light {
        display: inline-block;
      }
      &.icon-cirlce {
        border-radius: 50%;
        overflow: hidden;
      }
    }
    &.user {
      .icon {
        border: 2px solid $primary;
      }
    }
  }
}

/* Message Chat List */
#chat {
  padding-left: 0;
  margin: 0;
  list-style-type: none;
  overflow-y: auto;
  height: 350px;
  background-color: $msg-chat-bg-color;
  .message {
    padding: 10px;
    color: $white;
    max-width: calc(100% - 100px);
    display: inline-block;
    text-align: left;
    font-size: 12px;
    position: relative;
  }
  .time{
    color: $msg-notification-item-date-color;
    font-size: 10px;
  }
  li {
    padding: 10px;
    &.me {
      text-align: right;
      .message {
        &:before {
          content: "";
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 20px 2px 0px 15px;
          border-color: #00B0F0 transparent transparent transparent;
          display: inline-block;
          position: absolute;
          right: 13px;
          bottom: -18px;
          z-index: 1024;
        }
        background-color: #00B0F0;
      }
      .time{
        margin-right: 10px;
      }
    }
    &.you {
      .message {
        &:before {
          content: "";
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 20px 15px 0px 2px;
          border-color: #595959 transparent transparent transparent;
          display: inline-block;
          position: absolute;
          left: 13px;
          bottom: -18px;
          z-index: 1024;
        }
        background-color: #595959;
      }
      .time{
        margin-left: 10px;
      }
    }
  }
}
.message-chat-form,
.message-footer-content {
  padding: 10px;
}
.otp-controls{
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 10px;
  max-width: 250px;
  margin: 15px auto 0 auto;
}
/* Main Page Heading */
.main-page-heading {
  border-top: 1px solid $page-heading-border-color;
  border-bottom: 1px solid $page-heading-border-color;
  background: $page-heading-bg-color;
  .page-heading {
    color: $page-heading-text-color;
    font-weight: 500;
    font-size: 22px;
    padding: 16px 0;
    line-height: normal;
  }
}

/* Page Menu Block */
.page-menubar  {
  padding: 5px 15px;
  // background-color: $menubar-bg-color;
  // border-bottom: 1px solid $page-heading-border-color;
} 
[data-theme="light"] .page-menubar  {
  background-color: #F2F2F2;
} 
[data-theme="light"] .page-menubar.light-theme  {
  background-color: transparent !important;
}   
                                    

/* Page Menu Action */
.page-menu-actions {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  a {
    font-size: 14px;
    color: $page-menu-action-btn-text-color;
    padding: 3px 20px;
    text-decoration: none;
    &.active {
      background: $page-menu-action-btn-active-bg-color;
      color: $page-menu-action-btn-active-text-color;
      font-weight: 500;
    }
  }
}

/* Page Navbar */
.page-navbar {
  list-style: none;
  background-color: $navbar-bg-color;
  // border-right: 5px solid $nav-menu-border-color;
  width: 270px;
  height: 100% !important;
  transition: all 300ms ease;
  &.queue-sidebar{
    width: 320px;
  }
  & > a {
    font-size: 14px;
    display: block;
    text-decoration: none;
    color: $nav-item-text-color;
    margin-bottom: 1px;
    padding: 7px 8px 7px 10px;
    background: $nav-item-bg-color;
    border-left: 5px solid $nav-item-bg-color;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &:hover {
      font-size: 14px;
      color: $primary;
      text-decoration: none;
    }
    &.active {
      font-size: 15px;
      color: $primary;
      border-color: $primary;
    }
    .menu-icon {
      margin-right: 8px;
    }
  }
  &.collapsing {
    width: 0px;
  }
  .card {
    &:first-child {
      border-top: none;
    }
  }
}
.navbar-submenu {
  .card {
    border-width: 1px 0px 1px 0px;
    border-style: solid;
    border-color: $navbar-border-color;
  }
  .card-header {
    padding: 0px;
    background: transparent;
    border: none;
  }
  .card-body,
.accordion-body {
    padding: 7px 7px 7px 30px;
    background: $nav-item-bg-color;
  }
  .collapse,
  .collapsing {
    border-width: 0px 0px 0px 5px;
    border-style: solid;
    border-color: $primary;
  }
   .btn-link,
button.accordion-button,
  button.accordion-button {
    font-size: 14px;
    display: flex;
    align-items: center;
    text-align: left;
    width: 100%;
    text-decoration: none;
    padding: 7px 20px 7px 10px;
    border-width: 0px 0px 0px 5px;
    border-style: solid;
    background-repeat: no-repeat !important;
    background-position: right 7px center !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $nav-item-text-color;
    background: $nav-item-bg-color;
    border-color: $nav-item-bg-color;
    background-image: url("~/src/assets/images/sidebar/right-arrow.png");
    &[aria-expanded="true"] {
      border-color: $primary;
      background-image: url("~/src/assets/images/sidebar/bottom-arrow.png");
    }
    .menu-icon {
      margin-right: 8px;
    }
  }
}
.navbar-submenu {
  .module-menu {
.btn-link,
button.accordion-button {
      &[aria-expanded="true"] {
        border-color: #00ea6a;
      }
    }
    .collapse,
    .collapsing {
      border-color: #00ea6a;
    }
  }
  .card.active {
.btn-link,
button.accordion-button {
      border-color: $primary;
    }
    &.module-menu {
  .btn-link,
button.accordion-button {
        border-color: #00ea6a;
      }
    }
  }
}
.submenu-items {
  list-style: none;
  padding-left: 0px;
  .submenu {
    display: none;
    list-style: none;
    padding-left: 10px;
  }
  li[expandmenu] {
    & > a:before {
      content: "";
      display: inline-block;
      width: 15px;
      height: 15px;
      background-image: url("~/src/assets/images/sidebar/right-arrow.png");
      background-repeat: no-repeat;
      background-position: center;
      left: -2px;
      top: 50%;
      position: absolute;
      transform: translateY(-50%);
    }
    &.active {
      & > .submenu {
        display: block;
      }
      & > a:before {
        background-image: url("~/src/assets/images/sidebar/bottom-arrow.png");
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }
  a {
    font-size: 14px;
    padding: 5px 0px 5px 15px;
    position: relative;
    display: block;
    text-decoration: none;
    color: $nav-item-text-color;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    &:hover {
      font-size: 14px;
      color: $primary;
    }
    &.active {
      font-weight: 600;
      color: $primary;
    }
  }
}
.module-menu {
  .submenu-items {
    a {
      &:hover {
        color: #00ea6a;
      }
      &.active {
        color: #00ea6a;
      }
    }
  }
}

// User Navar Dropdown Profile
.user-profile-dropdown {
  width: 280px;
  background-color: $navbar-dropdown-bg-color;
  border-color: $navbar-dropdown-border-color;
  border-width: 3px;
  .dropdown-divider {
    border-color: $navbar-dropdown-border-color;
    border-width: 3px;
  }
  .user-profile {
    h6,
    p {
      color: $nav-item-text-color;
    }
  }
   .btn-link,
button.accordion-button,
  button.accordion-button {
    color: $navbar-dropdown-link-color;
    &:hover {
      color: $primary;
    }
  }
}

// User Active Status Online / Offline
.user-active-status {
  display: inline-flex;
  position: relative;
  align-items: center;
  font-size: 12px;
  &::after {
    content: "";
    display: inline-block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #ff0000;
    margin-left: 5px;
  }
  &.online {
    &::after {
      background-color: #00b050;
    }
  }
}

/* Light and Dark Icon Image  */
.icon-light {
  display: inline-block;
}
.icon-dark {
  display: none;
}

/* Login Form Wrapper */
.login-form-wrapper {
  .login-form-inner {
    min-height: calc(100vh - 154px);
  }
  .login-form {
    max-width: 400px;
    width: 100%;
    padding: 40px 60px;
    background: $white;
    @include media-breakpoint-down(sm) {
      padding: 40px 40px;
    }
  }
}

/* Login Detail Content */
.login-detail-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;
  background-image: url("~/src/assets/images/login-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  .title {
    font-size: 49px;
    font-weight: 900;
  }
  .sub-title {
    font-size: 45px;
    font-weight: 500;
  }
  .description {
    font-size: 20px;
  }
}

/* Loin Form Content */
.login-form-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;
  padding: 50px;
  background: $login-form-bg-color;
  @include media-breakpoint-down(sm) {
    padding: 10px;
  }
}

.modal-dialog {
  .login-form-content {
    background: $white;
    padding: 30px;
    .login-form {
      width: 100%;
    }
  }
}

.modal-dialog{
  .modal-content{
    border: 6px solid #a1a1a1;
    background-color: $white;
  }
}

.draggable-container {
  .draggable-modal-content{
      border: 6px solid #808080;
      background-color:  $white;
  }
  .modal-content {
      border: 0px !important;
      background-color: transparent !important;
  }
}


/* Main Page Wrapper */
.main-page-wrapper {
  padding: 16px;
  background-color: $page-main-wrapper-bg-color;
  // min-height: calc(100vh - 205px);
  min-height: calc(100vh - 60px);
  height: 100%;
}
[data-theme="light"] .main-page-wrapper {
  background-color: #E1E1E1;
}

// save & cancel btn align
.main-page-wrapper{
  padding-bottom: 70px;
  .form-action-buttons.row.justify-content-end {
    position: absolute;
    right: 15px;
    bottom: 0px;
  }
}

.page-content-inner,
.page-content-sidebar {
  background-color: $page-content-inner-bg-color;
}
.page-content-inner {
  padding: 20px;
}
.page-content-inner-select {
  min-height: 500px;
}

/* Lms Card Style */
.lms-card {
  background: $lms-card-bg-color;
  padding: $lms-card-padding;
  color: $lms-card-text-color;
  font-size: $lms-card-text-size;
  .lms-card-heading {
    font-size: $lms-card-heading-size;
    color: $lms-card-heading-color;
  }
  .lms-card-header {
    &[aria-expanded="false"] {
      .icon-arrow-right {
        display: inline-block;
      }
      .icon-arrow-down {
        display: none;
      }
    }
    &[aria-expanded="true"] {
      .icon-arrow-right {
        display: none;
      }
      .icon-arrow-down {
        display: inline-block;
      }
    }
  }
}

.lms-card {
  &.lms-bg-dark {
    background: $lms-card-bg-color;
  }
  .module-heading-success {
    color: $module-color-success;
  }
  .module-heading-yellow {
    color: $module-color-yellow;
  }
}
/* Lms Module Card Style */
.lms-module-card {
  background: $lms-card-bg-color;
  color: $lms-card-text-color;
  font-size: $lms-card-text-size;
  .lms-module-heading {
    span {
      display: inline-block;
      min-width: 225px;
      font-weight: 500;
      font-size: 16px;
      color: #fff;
      padding: 3px 16px;
      background: $module-color-success;
      box-shadow: 4px 4px 1px 1px rgba(0, 0, 0, 0.2) !important;
    }
    &.lms-heading-yellow {
      span {
        background-color: $module-color-yellow;
      }
    }
  }
  .card-body,
.accordion-body {
    padding: $lms-card-padding;
  }
}
// Button Style
.btn-help-info {
  font-weight: 500;
  font-size: $btn-help-text-size;
  color: $btn-help-text-color;
  text-decoration: none !important;
  span {
    margin-right: 5px;
  }
  &.is-hover {
    position: relative;
    span {
      position: absolute;
      right: 100%;
      top: 0px;
      display: none;
      white-space: nowrap;
      color: $btn-help-text-color;
    }
    &:hover {
      span {
        display: block;
        color: $btn-help-text-color;
      }
    }
  }
}
.btn-add-new {
  font-size: 12px;
  background-color: $btn-add-new-bg-color;
  border: 1px solid $btn-add-new-border-color;
  position: relative;
  padding-right: 40px;
  margin-top: 15px;
  img {
    background-color: $primary;
    position: absolute;
    right: -1px;
    top: -1px;
    width: 29px;
    height: calc(100% + 2px);
    padding: 8px;
    object-fit: contain;
  }
  &:hover {
    background-color: $primary;
    border-color: $primary;
    color: $white;
  }
  &.btn-add-new-success {
    img {
      background: #00ea6a;
    }
    &:hover {
      background-color: #00ea6a;
      border-color: #00ea6a;
    }
  }
  &.btn-add-new-warning {
    img {
      background: $warning;
    }
    &:hover {
      background-color: $warning;
      border-color: $warning;
    }
  }
}
.btn-upload,
.btn-duplicate {
  font-size: 12px;
  background-color: $btn-add-new-bg-color;
  border: 1px solid $btn-add-new-border-color;
  position: relative;
  padding-right: 40px;
  img {
    background-color: $primary;
    position: absolute;
    right: -1px;
    top: -1px;
    width: 29px;
    height: calc(100% + 2px);
    padding: 8px;
    object-fit: contain;
  }
  &:hover {
    background-color: $primary;
    border-color: $primary;
    color: $white;
  }
  &.btn-duplicate-success,
  &.btn-upload-success {
    img {
      background: #00ea6a;
    }
    &:hover {
      background-color: #00ea6a;
      border-color: #00ea6a;
    }
  }
}
.btn-select-success {
  &:hover {
    text-decoration: underline;
  }
}
.refresh-button {
  font-size: 12px;
  position: relative;
  padding: 3px 10px;
}
.btn-file-remove,
.btn-delete,
.btn-file-upload {
  font-size: 12px;
  position: relative;
  padding: 3px 40px 3px 10px;
  img {
    position: absolute;
    right: -1px;
    top: -1px;
    width: 29px;
    height: calc(100% + 2px);
    padding: 4px;
    object-fit: contain;
  }
}
.btn-file-upload {
  background-color: $btn-add-new-bg-color;
  border: 1px solid $btn-add-new-border-color;
  img {
    background-color: $primary;
  }
  &:hover {
    background-color: $primary;
    border-color: $primary;
    color: $white;
  }
  &.btn-icon-success {
    img {
      background-color: $btn-file-success-bg-color;
    }
    &:hover {
      background-color: $btn-file-success-bg-color;
      border-color: $btn-file-success-bg-color;
    }
  }
}
.btn-file-remove,
.btn-delete {
  background-color: $btn-add-new-bg-color;
  border: 1px solid $btn-add-new-border-color;
  img {
    background-color: $danger;
  }
  &:hover {
    background-color: $danger;
    border-color: $danger;
    color: $white;
  }
}
.btn-login-client {
  font-size: 12px;
  background-color: $btn-add-new-bg-color;
  border: 1px solid $btn-add-new-border-color;
  position: relative;
  padding: 3px 40px 3px 10px;
  img {
    background: #00ea6a;
    position: absolute;
    right: -1px;
    top: -1px;
    height: calc(100% + 2px);
    padding: 5px;
    object-fit: contain;
  }
  &:hover {
    background-color: #00ea6a;
    border-color: #00ea6a;
    color: $white;
  }
}
.btn-icon {
  background: transparent;
  border: 0;
}
.btn-remove {
  margin-top: 3px;
}
.show-label {
  .btn-remove {
    margin-top: 30px;
  }
}

// File Upload Block
.file-upload-block {
  background-color: $file-upload-bg-color;
  padding: 15px;
  .file-upload-heading {
    color: $file-upload-text-color;
    font-size: 14px;
    font-weight: 400;
  }
  &.mw-250 {
    max-width: 250px;
  }
}
.favicon-preview,
.profile-preview {
  object-fit: cover;
  border: 2px solid $white;
}
.logo-preview {
  object-fit: contain;
  width: 100%;
  max-width: 150px;
  height: 58px;
  padding: 5px;
  &.light-logo {
    background-color: $white;
  }
  &.dark-logo {
    background-color: #161616;
  }
}

// Save Date Time Info and Save Version
.save-datetime-info,
.save-version-info {
  font-size: $save-datetime-text-size;
}
.save-datetime-info {
  strong {
    display: inline-block;
  }
}
.btn-version-prev,
.btn-version-next {
  img {
    filter: invert(1);
  }
}

.img-invert{
  filter: invert(1);
}

// lms-client-list
.lms-user-list,
.lms-client-list{
  max-height: calc(100% - 62px);
}
.lms-client-list,
.lms-user-list {
  height: 100%;
  width: 275px;

  @media screen and (min-width: 1440px) {
    width: 350px;
  }
  .client-header,
  .user-header {
    padding: 10px;
  }
  .client-body,
  .user-body {
    padding: 10px;
    overflow-y: auto;
    max-height: calc(100% - 140px);
  }
}

// Client Nav
.client-nav,
.user-list-nav {
  padding-left: 10px;
  padding-right: 10px;
  .nav-item {
    font-size: 11px;
    font-weight: 700;
    color: $client-nav-text-color;
    letter-spacing: 0.5px;
    padding-left: 0px;
    padding-right: 0px;
    margin-right: 10px;
    &.active {
      span {
        border-bottom: 1px solid $client-nav-active-color;
      }
    }
  }
}

// Client Item
.client-item,
.user-list-item {
  padding: 7px;
  background: $client-item-bg-color;
  margin-bottom: 9px;
  border: 1px solid $client-item-border-color;
  cursor: pointer;
  &:hover {
    background-color: $client-item-active-bg-color;
  }
  &.active {
    background-color: $client-item-active-bg-color;
    border: 2px solid $client-item-active-border-color;
  }
  .client-logo,
  .user-image {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    object-fit: cover;
  }
  .client-name,
  .user-name {
    color: $client-item-text-color;
    font-size: 14px;
    font-weight: 700;
  }
  .client-description,
  .user-description {
    font-size: 11px;
    color: $client-item-text-color;
  }
  .client-status,
  .user-status {
    font-size: 11px;
    text-align: right;
    display: inherit;
    &.active {
      color: $client-item-text-active;
    }
    &.terminated {
      color: $client-item-text-terminated;
    }
    &.inactive {
      color: $client-item-text-inactive;
    }
  }
  .user-active-status {
    display: inherit;
  }
}

// Versions Date
.versions-date {
  max-width: 970px;
  background: #c4c4c4;
  overflow-x: auto;
  margin-bottom: 5px;
  width: 100%;
}

.version-date {
  color: #565656;
  background: #efefef;
  border: 1px solid #C4C4C4;
  width: 35px;
  height: 146px;
  cursor: pointer;
  &:hover {
    border-color: #fff000;
  }
  &.version-inactive {
    background-color: #dbdbdb;
  }
  &.version-active {
    background-color: $white;
  }
  &.version-draft {
    background-color: #c5e0f9;
  }
  &.version-selected {
    border-width: 2px;
    border-color: #fff000;
  }
  .date-head,
  .date-foot {
    padding-top: 2px;
    padding-bottom: 2px;
    color: #fff;
    height: 22px;
  }
  .date-head {
    background: #565656;
  }
  .date-foot {
    background: #1b1b1b;
  }
  .date-body {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      transform: rotate(-90deg);
      display: inline-block;
    }
  }
}
// Notification Sidebar
.lms-notification-sidebar,
.lms-datawarehouse-sidebar {
  height: 100%;
  .lms-notification-sidebar-content {
    background-color: $notification-bg-color;
  }
  .lms-notification-header,
  .lms-datawarehouse-header {
    padding: 10px 10px 0px 10px;
  }
  .lms-notification-body,
  .lms-datawarehouse-body {
    padding: 0px;
    height: 100%;
    max-height: 700px;
    overflow: auto;
  }
}

.lms-role-sidebar {
  height: 100%;
  .lms-role-sidebar-content {
    background-color: $notification-bg-color;
  }
  .lms-role-header {
    padding: 10px 10px 0px 10px;
  }
  .lms-role-body {
    padding: 0px;
    min-height: calc(100vh);
    height: 100%;
    max-height: 1275px;
    overflow: auto;
  }
}

// Notification Sidebar Nav
.lms-notification-list-nav ,
.lms-message-list-nav {
  padding-left: 10px;
  padding-right: 10px;
  .nav-item {
    font-size: 11px;
    font-weight: 700;
    color: $notification-nav-text-color;
    letter-spacing: 0.5px;
    padding-left: 0px;
    padding-right: 0px;
    margin-right: 10px;
    &.active {
      span {
        border-bottom: 1px solid $notification-nav-active-color;
      }
    }
  }
}

// Card Header
.lms-card-header-primary {
  background-image: url("~/src/assets/images/sidebar/right-arrow-dark.png");
  background-repeat: no-repeat !important;
  background-position: right 12px center !important;
  &[aria-expanded="true"] {
    background-image: url("~/src/assets/images/sidebar/bottom-arrow-dark.png");
  }
  &[data-toggle="collapse"] {
    &.text-center {
      padding-right: 30px;
      padding-left: 30px;
    }
  }
  &.lms-card-header-success {
    background-color: #006c31;
  }
}

// Category Listing
.category-listing {
  .card {
    border: none;
    margin-bottom: 0px;
  }
  .card-header {
    padding: 0px;
    background: transparent;
    border: none;
  }
  .category-actions {
    position: absolute;
    right: 0px;
    top: 0px;
    margin-top: 2px;
    z-index: 1;
    .btn-icon {
      margin-top: 0px;
    }
  }
  .card-body,
.accordion-body {
    padding: 0px;
  }
  .btn-link,
  button.accordion-button {
    font-size: 14px;
    text-align: left;
    width: 100%;
    border: none;
    text-decoration: none;
    padding: 5px 65px 5px 34px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: $category-item-bg-color;
    color: $white;
    background-image: url("~/src/assets/images/sidebar/right-arrow-dark.png");
    background-repeat: no-repeat !important;
    background-position: left 15px center !important;
    &[aria-expanded="true"] {
      background-image: url("~/src/assets/images/sidebar/bottom-arrow-dark.png");
    }
  }
  &.level-1 {
    .card-header {
  .btn-link,
button.accordion-button {
        color: $white !important;
        padding: 5px 15px 5px 34px;
        background-color: $category-item-bg-color;
        [data-theme="light"] & {
          background-color: $category-item-bg-color-light;
        }
      }
    }
    .category-items a {
      padding: 2px 5px 2px 35px;
    }
  }
  &.level-bg-success {
    .card-header {
  .btn-link,
button.accordion-button {
        background-color: $success;
        color: $white;
        [data-theme="light"] & {
          background-color: $success;
        }
      }
    }
  }
  &.level-2 {
    .card-header {
  .btn-link,
button.accordion-button {
        padding: 5px 15px 5px 50px;
        background-position: left 30px center !important;
        background-color: $category-item-bg-color;
      }
    }
    .category-items a {
      padding: 2px 5px 2px 50px;
    }
  }
  &.level-3 {
    .card-header {
  .btn-link,
button.accordion-button {
        padding: 5px 15px 5px 75px;
        background-position: left 50px center !important;
        background-color: $category-item-bg-color;
      }
    }
    .category-items a {
      padding: 2px 5px 2px 75px;
    }
  }
  .category-items a {
    display: block;
    font-size: 14px;
    color: $category-link-color;
    span {
      display: inline-block;
      min-width: 36px;
      margin-right: 10px;
    }
    &.active {
      color: $category-link-active-color !important;
    }
    &.inactive {
      color: $category-link-inactive-color;
    }
  }
  &.category-listing-success {
.btn-link,
button.accordion-button:hover {
      color: $module-color-success;
      [data-theme="dark"] & {
        color: $dark-module-color-success;
      }
    }
    .category-items a {
      &.active {
        color: $module-color-success !important;
        [data-theme="dark"] & {
          color: $dark-module-color-success !important;
        }
      }
    }
  }
  &.card-header-bg-transparent{
.btn-link,
button.accordion-button{
      background-color: transparent !important;
      color: #333;
      background-image: url("~/src/assets/images/sidebar/right-arrow.png");
      &[aria-expanded="true"] {
        background-image: url("~/src/assets/images/sidebar/bottom-arrow.png");
      }
    }
  }
}

// Role Listing
.roles-table {
  width: 100%;
  thead {
    th {
      padding-top: 5px;
      padding-bottom: 5px;
      font-weight: 400;
    }
  }
  table {
    width: 100%;
  }
   .btn-link,
button.accordion-button,
  button.accordion-button {
    font-size: 14px;
    text-align: left;
    border: none;
    text-decoration: none;
    padding: 5px 15px 5px 15px;
    background-image: url("~/src/assets/images/sidebar/right-arrow-dark.png");
    background-repeat: no-repeat !important;
    background-position: center !important;
    &[aria-expanded="true"] {
      background-image: url("~/src/assets/images/sidebar/bottom-arrow-dark.png");
    }
  }
  tbody {
    color: $white;
    font-size: 14px;
  }
  td[colspan] {
    padding: 0px;
  }
  .custom-checkbox {
    margin-bottom: 2px;
    margin-top: 10px;
    min-width: 150px;
    .custom-control-label {
      &:before,
      &:after {
        top: 0px;
        background-color: $white;
      }
    }
    .custom-control-input:checked ~ .custom-control-label::after {
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAPCAYAAADphp8SAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAK+SURBVHgBlVJbSJNRHD/n+84u6m7qmlMnm5cRlpmEgUkmRiQYJREViCKSUupTBJJvIRGBMYIKH1IjegjMhxIjIUshI6FVGupcW87mptnGnJu7fPsup++bFCJZ6/d0OOfP7/+7HAj+Ay99N5Vv1l/X2SPmDorzKQgAWAYjcSF5pJ9IlMSMzSLrhm3/CmVvjbA+AwlAWowl1Bkiw9dK7dnEiSyeYf1yzN7gpRf3IggAgwGQI/VqtqSoNz2VsCZE1OfpkNtCb88sUB/PEYAjAE8CgSSSl1L8SCc1vCiFF2kkDA78uCcDxNqeALVKl2QVzQgPW4m4GFXmpqwtFBdUiOJqIN4l0r1Tg4LHBm2tC4DbAA3gAdLpHDq0EP7USUE66nG570/gvtHD8EJQILEHpzV3lhuu+mLuXCSkG7eU4ciVHugr19dYS2EVI8wh0uvWfGccl1z0lwp+GzMbCOrZbzh/ZMnUX6277O9Zaat3RC0VYoIlsGAJyoIaUd5gkaqKt3Qq/Es1WgxNNzsiU8fEMG4TRTjP7rnQWDuSccmsD8+a/UPNCNIiIUwGQKxEqfPF8qpnJ5StfgDafttHEiRRAYylQgtifhoDhoxxfoM5+Lzzc2h0g+JC6SSx2ZIIKlcKk8sfFGpOv4cQ4q05krVXTk5oJVnOCEsVrLP+VJJvhYBCKxxiOTqZP0PBEglk3tykfU/bDN23jLAkur1Zctg0To+YBufWOWwOcl5ViA1m86okQIgDbg7R/HY10o03ZHZ3GaXly3/6IvH6ITRSGOPJKFi7bglI/G7KVsOAQKagTmgpBardlYq63lJF9TzYAXD7xSvvw+yJjSd1C+GZJj/jMgJAcAflx02Nhp6uHJgTSZhIgA1PKoaX7h61hD80ppAq6fm09pay9HoX+AvgTg9jY9fQtN6ZrxRrk5p0N6bAP/ATj4gvjHZ9UikAAAAASUVORK5CYII=");
      background-size: 13px;
    }
    .custom-control-input:disabled ~ .custom-control-label{
      color: $white;
      cursor: not-allowed;
    }
  }

  // Roles Disabled
  &.roles-table-disabled{
    .custom-checkbox {
      .custom-control-input:checked ~ .custom-control-label::after {
        //filter: grayscale(1);
        background-image:url('/assets/images/icons/roles-check-black.png') !important;

      }
      input[type="checkbox"]{
        pointer-events: none !important;
      }
    }
  }

  .permissions {
    width: 320px;
    white-space: nowrap;
  }
  .expand-action {
    width: 50px;
    white-space: nowrap;
  }
  .permission-custom {
    width: 130px;
    white-space: nowrap;
    padding-left: 30px;
  }
  .permission-name {
    min-width: 130px;
    white-space: nowrap;
  }
  .level-1 {
    & > tr {
      background: $role-level-1-bg-color;
    }
  }
  .level-2 {
    & > tr {
      background: $role-level-2-bg-color;
      > .permission-name{
        padding-left: 15px;
      }
    }
  }
  .level-3 {
    & > tr {
      background: $role-level-3-bg-color;
      > .permission-name{
        padding-left: 30px;
      }
    }
  }
  .level-4 {
    & > tr {
      background: $role-level-4-bg-color;
      > .permission-name{
        padding-left: 45px;
      }
    }
  }
  .bg-danger {
    background: $role-bg-danger-color;
  }
}

.category-sort-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  .btn-category-sort-down,
  .btn-category-sort-up {
    padding: 0px 5px;
    width: 20px;
    height: 10px;
    line-height: 0px;
  }
}

// Applicant Sidebar
.applicant-sidebar {
  background-color: $applicant-sidebar-bg-color;
//   border-right: 5px solid $applicant-sidebar-border-color;
  min-height: 100%;
  & > div:not(:first-child) {
    margin-top: 1px;
  }
  .card,
  .accordion-item {
    padding: 0px;
    border: none;
    margin-top: 1px;
    background-color: $applicant-card-bg-color;
    .card,
    .accordion-item {
      margin: 0px -10px;
      &:last-child {
        margin-bottom: -10px;
      }
      .card-header,
      .accordion-header {
        background-color: #ffffff;
        color: #3a3838;
        display: flex;
        justify-content: space-between;
        padding: 8px 10px 8px 0px;
        border: none;
        .btn-link,
        button.accordion-button {
          background-color: transparent;
          width: auto;
          background-image: url("~/src/assets/images/sidebar/right-arrow.png");
          &[aria-expanded="true"] {
            background-image: url("~/src/assets/images/sidebar/bottom-arrow.png");
          }
        }
      }
      .card-body,
      .accordion-body {
        background-color: #f5f5f5;
      }
    }
  }
  .card-header,
  .accordion-header {
    padding: 0px;
    .btn-link,
    button.accordion-button {
      background-color: $success;
      color: $white;
      width: 100%;
      font-size: 14px;
      text-align: center;
      width: 100%;
      text-decoration: none;
      padding: 7px 20px 7px 10px;
      background-repeat: no-repeat !important;
      background-position: right 7px center !important;
      background-image: url("~/src/assets/images/sidebar/right-arrow-dark.png");
      &[aria-expanded="true"] {
        background-image: url("~/src/assets/images/sidebar/bottom-arrow-dark.png");
      }
    }
  }
  a{
    color: #3a3838;
  }
  .card-body,
.accordion-body {
    padding: 10px;
    font-size: 14px;
  }
  .sidebar-actions {
    background: $applicant-sidebar-actions-bg-color;
    padding: 10px 0px;
    .btn-sidebar-queue,
    .btn-sidebar-next {
      font-weight: 500;
    }
  }
  .sidebar-outbound-call {
    background: $applicant-sidebar-outbound-call-bg-color;
    padding-top: 5px;
    padding-bottom: 5px;
    // .w-30 {
    //   width: 30px;
    // }
    .w-40 {
        width: 40px;
      }
  }
  .btn-outbound-call {
    background: $applicant-btn-outbound-call-bg-color;
    border: 1px solid $applicant-btn-outbound-call-border-color;
    color: $applicant-btn-outbound-call-text-color;
    &:hover {
      background: darken($applicant-btn-outbound-call-bg-color, 10);
    }
  }
}

// Module Sidebar Heading
.module-sidebar-heading {
  font-size: 14px;
  display: flex;
  align-items: center;
  text-align: left;
  width: 100%;
  text-decoration: none;
  padding: 7px 20px 7px 10px;
  border-width: 0px 0px 0px 5px;
  border-style: solid;
  background-repeat: no-repeat !important;
  background-position: right 7px center !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: $applicant-sidebar-heading-text-color;
  background: $applicant-sidebar-heading-bg-color;
  border-color: $applicant-sidebar-heading-border-color;
  .menu-icon {
    margin-right: 8px;
  }
  &.module-success {
    border-color: $applicant-sidebar-heading-border-success-color;
  }
}

// Module Header Info
@media(max-width:1280px){
  .module-header-info{
    height: 90px;
  }
}

.module-header-info {
  height: 47px;
  overflow-y: hidden;
  border-bottom: 1px solid #cccccc;
  padding: 5px 15px;
  justify-content: space-between;
  & > div[class^="col"] {
    flex: 0 0 20%;
    max-width: 20%;
  }
  strong {
    margin-left: 5px;
  }
  // .module-info-item {
  //   display: grid;
  //    grid-template-columns: repeat(2, 1fr);
  //   grid-template-columns: 120px 170px ;
  // }
}

// Module NavTabs
.module-tabs.nav-tabs {
  border-bottom: 0px;
  margin-bottom: 12px;
  margin-top: -43px;
  position: relative;
  &:before {  
    content: "";
    width: 100%;
    height: 5px;
    left: 0px;
    bottom: 0px;
    background: $module-color-success;
    position: absolute;
    z-index: 1;
  }
  .nav-item {
    padding: 0px 10px;
  }
  .nav-link {
    padding: 3px 15px;
    margin-bottom: 0px;
    border-radius: 0px;
    border-width: 1px 1px 0px 1px;
    border-style: solid;
    border-color: transparent transparent $module-color-success transparent;
    font-size: 14px;
    color: #3a3838;
    background: #bfbfbf;
  }
  .nav-link.active,
  .nav-item.show .nav-link {
    color: #3a3838;
    background-color: #bfbfbf;
    border-color: $module-color-success $module-color-success #bfbfbf;
    position: relative;
    z-index: 2;
  }
  &.module-tabs-yellow {
    &:before {
      background: $module-color-yellow;
    }
    .nav-link {
      border-color: transparent transparent $module-color-yellow transparent;
    }
    .nav-link.active,
    .nav-item.show .nav-link {
      border-color: $module-color-yellow $module-color-yellow #bfbfbf;
    }
  }
}
// Module Button With Shadow
.module-btn-shadow {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background: $primary;
  padding: 10px 10px 0px 10px;
  color: $white;
  margin: 0px 10px;
  box-shadow: 4px 4px 0px 1px #dbdbdb !important;
  // width: 85px;
  width : auto;
  text-decoration: none !important;
  font-size: 14px;
  border: none;
  &:after {
    position: absolute;
    right: 20px;
    top: 50%;
    width: 10px;
  }
  &:hover {
    color: $white;
  }
}
[data-theme=light] .module-btn-shadow{
  background-color: #3889ED;
}
.tools-dropdowns {
  .dropdown-menu {
    font-size: 14px;
    left: -50px !important;
    transform: translate3d(-44%, 50px, 66px) !important;
    max-width: 245px !important;
    width: 245px !important;
    .dropdown-item:active{
      background-color: transparent !important;
    }
  }
}
.module-msg-description {
  background: #f2f2f2;
  padding: 5px 10px;
}

.btn-module-graph {
  padding: 7px 10px;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-image: url("~/src/assets/images/sidebar/right-arrow.png");
  &[aria-expanded="true"] {
    background-image: url("~/src/assets/images/sidebar/bottom-arrow.png");
  }
}

// Reference Card
.reference-card {
  border-radius: 5px;
  background: $reference-card-bg-color;
  border: 1px solid $reference-card-border-color;
  border-radius: 5px;
  position: relative;
  .reference-btn-close {
    position: absolute;
    right: -6px;
    top: -10px;
    z-index: 1;
  }
  .reference-card-title {
    font-size: 16px;
    color: #00ea6a;
    margin-top: 5px;
  }
}

/* Version List Style */
.lms-version-card {
  background: $version-card-bg-color;
  color: $version-card-text-color;
  font-size: $version-card-text-size;
  padding: $version-card-padding;
  .lms-version-heading {
    font-size: $version-heading-size;
    color: $version-heading-color;
  }
  [aria-expanded="false"] {
    .icon-arrow-right {
      display: inline-block;
    }
    .icon-arrow-down {
      display: none;
    }
  }
  [aria-expanded="true"] {
    .icon-arrow-right {
      display: none;
    }
    .icon-arrow-down {
      display: inline-block;
    }
  }
  .col-action {
    width: 75px;
  }
}

/* Transaction Payment Info Table */
$payment-info-bg-color: $white;
$payment-info-detail-border-color: #e3e3e3;
$dark-payment-info-bg-color: $dark-black;
$dark-payment-info-detail-border-color: #888888;
$payment-detail-success-color: #00ea6a;
.payment-info {
  background-color: $payment-info-bg-color !important;
  & > td {
    padding: 0px;
  }
  .payment-info-detail {
    padding: 10px;
    &:not(:last-child) {
      border-right: 1px solid $payment-info-detail-border-color;
    }
  }
  .payment-label {
    font-weight: 500;
  }
  .text-success {
    color: $payment-detail-success-color !important;
  }
}

[data-theme="dark"] {
  /* Transaction Payment Info Table */
  .payment-info {
    background-color: $dark-payment-info-bg-color !important;
    .payment-info-detail {
      &:not(:last-child) {
        border-color: $dark-payment-info-detail-border-color;
      }
    }
    .text-success {
      color: $payment-detail-success-color !important;
    }
  }
}
// Queue Last Work Info
.queue-lastwork-info {
  span {
    display: inline-block;
    // width: 80px;
    // margin-right: 10px;
    // text-align: right;
  }
}

// Notification Preview
.notification-preview {
  background: #c4c4c4;
  padding: 10px;
  min-height: 768px;
  height: 100%;
  max-height: calc(100vh - 260px);
  overflow: auto;
  .notification-preview-content {
  }
}

.notification-email-sidebar {
  padding: 10px;
  height: 100%;
}
.notification-email-content-inner {
  min-height: 100%;
  .notification-email-content-heading {
    font-size: 14px;
  }
}
// Notification Email and Mail Listing
.notification-email-list {
  max-height: calc(100vh - 190px);
  overflow-y: auto;
  .notification-email-item {
    margin-bottom: 10px;
    font-size: $notification-email-font-size;
    line-height: $notification-email-lineheight;
    border: 1px solid $notification-email-border-color;
    .notification-email-header {
      background-color: $notification-email-header-bg-color;
      padding: $notification-email-space;
      font-size: 12px;
      .date {
        color: #8ad5ff;
      }
      .title {
        font-size: 14px;
        font-weight: 600;
      }
    }
    .notification-email-body {
      background-color: $notification-email-body-bg-color;
      font-size: 12px;
      line-height: 20px;
      display: none;
      .notification-bg-dark {
        background-color: $notification-info-bg-color;
      }
    }
    &:hover {
      border-color: $notification-email-border-active-color;
    }
    &.active,
    &.selected {
      border-color: $notification-email-border-active-color;
      .notification-email-body {
        display: block;
      }
    }
  }
}

/* Email Attachment Item */
.email-attachment-item {
  padding: 15px;
  background: #e9e9e9;
  font-size: 14px;
  color: #6f6f6f;
  border: 1px solid #acacac;
  height: 100%;
}

/* Modal Style */
.modal-title {
  &.modal-title-success {
    color: $module-color-success;
  }
  &.modal-title-yellow {
    color: $warning;
  }
}
.import-tool-result {
  font-size: 14px;
  .row {
    border-bottom: 1px solid rgba(#ccc, 0.5);
    margin-left: -16px;
    margin-right: -16px;
    padding: 5px;
  }
}
.import-tool-note {
  font-size: 14px;
}
.file-upload-list {
  .file-upload-item {
    margin-bottom: 10px;
  }
  .file-name {
    background: $file-name-bg-color;
    padding: 5px 30px 5px 10px;
    position: relative;
    font-size: 14px;
    min-height: 31px;
    .btn-remove {
      position: absolute;
      right: 0px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 2;
      margin: 0px;
    }
  }
}
// conversion-rule
.conversion-rule {
  background: #b7b7b7;
  color: #404040;
  display: inline-block;
  padding: 5px 10px;
  cursor: pointer;
  &.active{
    background: #00EA6A;
  }
  [data-theme="dark"] & {
    background: #595959;
    color: #404040;
    &.active{
      background: #00EA6A;
    }
  }
}

.calculate-bad-rate-conversion {
  .form-control {
    text-align: center;
  }
}

.characteristic-container,
.assignment-container {
  padding: 15px;
  background: $characteristic-bg-color;
  margin-left: -16px;
  margin-right: -16px;
  .lms-card {
    background: transparent !important;
    padding: 0px;
  }
}
.characteristic-grid {
  display: grid;
  grid-template-columns: 75px 100px 1fr 85px 30px;
  column-gap: 5px;
  .characteristic-item {
    margin-bottom: 5px;
  }
}

.depreciation-estimate-grid {
  display: grid;
  grid-template-columns: 150px 1fr 1fr 1fr 1fr 1fr 1fr 1fr 30px;
  .depreciation-estimate-item {
    margin-bottom: 5px;
  }
}
.risk-rating-mix-calculation-header{
  padding-left: 100px;
  .form-control{
    max-width: 350px;
    max-width: fit-content;
    text-align: center;
    margin-bottom: 25px;
  }
}
.risk-rating-mix-calculation-grid {
  display: grid;
  grid-template-columns: 90px 20px 90px minmax(120px, 1fr) 90px 90px 200px;
  &.risk-rating-mix-calculation-grid-4{
    grid-template-columns: 120px 20px 120px 1fr;
  }
  .risk-rating-mix-calculation-item {
    margin-bottom: 5px;
  }
}
.calculate-nbar-grid {
  display: grid;
  grid-template-columns: 1fr 120px;
  .calculate-nbar-item {
    margin-bottom: 5px;
  }
}
.nbar-calculation-grid {
  display: grid;
  grid-template-columns: 1fr 20px 1fr 20px 1fr;
  .nbar-calculation-item {
    margin-bottom: 5px;
  }
}
.sub-status-codes-grid {
  display: grid;
  grid-template-columns: 30px 1fr 30px 1fr 30px 1fr;
  .sub-status-codes-item {
    margin-bottom: 5px;
  }
}

.active-collections-scorecards-grid {
  display: grid;
  grid-template-columns: 1fr 174px;
}
.active-collections-scorecards-content {
  position: relative;
  min-height: 75px;
  padding-left: 26px;
  margin-bottom: 10px;
  .active-collections-scorecards-content-item + .active-collections-scorecards-content-item{
    margin-top: 5px;
  }
  .scorecards-bucket {
    position: absolute;
    font-size: 10px;
    background: #ccc;
    left: 0px;
    top: 0px;
    white-space: nowrap;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 100%;
    color: $black;
    font-size: 12px;
    &.bucket-0{
      background: #A9D08E;
    }
    &.bucket-1{
      background: #FFD966;
    }
    &.bucket-2{
      background: #E2AC00;
    }
    &.bucket-3{
      background: #C65911;
    }
    span{
      transform: rotate(-90deg);
      display: inline-block;
    }
  }
}

.odd-roll-left-grid{
  display: grid;
  grid-template-columns: 150px 70px;
  .odd-roll-left-item {
    margin-bottom: 5px;
  }
}
.odd-roll-right-grid{
  display: flex;
  .form-control{
    width: 70px;
  }
  .odd-roll-right-item {
    margin-bottom: 5px;
  }
}

// Leaderboard List
.leaderboard-list{
  .row + .row{
    margin-top: 10px;
  }
}

// Categorical Rule List
.categorical-rule-grid{
  display: grid;
  // grid-template-columns: 80px 120px 250px;
  grid-template-columns: 75px 125px 100px 20px 100px 20px;
  grid-gap: 10px;
  .categorical-rule-item {
    margin-bottom: 5px;
  }
}

// Assignment Header
.assignment-header{
  border-bottom: 1px solid #595959;
  margin-bottom: 10px;
}
// Assignment Grid
.assignment-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 85px 95px 85px 30px;
  column-gap: 10px;
  .assignment-item {
    margin-bottom: 5px;
  }
}
// Risk Grade List
.risk-grade-list{
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 5px;
  .risk-grade-item{
    background: #808080;
    border: 1px solid #808080;
    text-align: center;
    padding: 2px;
    &.active,
    &.selected{
      border-color: $dark-module-color-success;
      color: $dark-module-color-success;
    }
  }
}
[data-theme=dark] .risk-grade-list{
  .risk-grade-item{
    background: #3A3838;
  }
}

// maunal waterfall breakdown grid
.maunal-waterfall-breakdown-grid{
    display: grid;
    grid-template-columns: 1fr 175px 100px 150px;
    grid-gap: 0px 15px;
    .maunal-waterfall-breakdown-item{
        margin-top: 10px;
    }
}
.maunal-payment-breakdown-grid-header{
    grid-template-columns: 1fr 120px 80px 140px;
}
.maunal-waterfall-breakdown-grid-2{
    grid-template-columns: 1fr 1fr !important;
}
//waterfall-sequence-grid
.waterfall-sequence-grid{
  display: grid;
  grid-template-columns: 1fr 35px;
  grid-gap: 5px;
  .waterfall-sequence-item{
    margin-top: 5px;
  }
}

[data-theme=dark] .active-waterfall-list{
    background-color: #3A3838 ;
}
 .active-waterfall-list{
  background-color:#f2f2f2;
  height: 210px;
  overflow-y: scroll;
  .active-waterfall-item{
    margin: 0px;
    &.active,
    &.selected{
      color: $green-dark;
    }
  }
}

.specify-rule-grid{
  display: grid;
  // grid-template-columns: 75px 1fr 75px 1fr;
  // grid-template-columns: 100px 1fr 100px 1fr 15px;
  grid-template-columns: 100px 1fr 100px 100px 1fr;
  grid-gap: 5px;
}


.payment-method-filter,
.payment-code-filter{
  .client-nav,
  .user-list-nav{
    border: none !important;
  }
}
.payment-code-grid{
  display: grid;
  grid-template-columns: minmax(20px, auto) 100px 1fr;
  grid-gap: 10px;
  &.inactive{
    opacity: 0.5;
  }
}
.payment-custom-code-grid{
  display: grid;
  grid-template-columns: minmax(20px, auto) 1fr;
  grid-gap: 10px;
  margin-bottom: 10px;
  &.inactive{
    opacity: 0.5;
  }
}
.payment-custom-code-grid-2{
  display: grid;
  grid-template-columns: 100px 1fr 10px;
  grid-gap: 10px;
  margin-top: 5px;
}
.payment-custom-code-grid-3{
  display: grid;
  grid-template-columns: 100px 1fr 100px;
  grid-gap: 10px;
  margin-top: 5px;
}

.payment-method-grid{
  display: grid;
 grid-template-columns: minmax(20px, auto) 1fr 1fr;
  grid-gap: 10px;
  &.inactive{
    opacity: 0.5;
  }
}
.payment-method-wrapper{
    .waterfall-sequence-item {
        position: absolute;
        top: -2px;
        right: -40px;
    }
}


.specify-parameters-grid{
  display: grid;
  // grid-template-columns: auto 183px auto auto auto 77px;
  grid-template-columns: 180px 270px 220px 120px 220px 150px 10px;
  grid-gap: 5px;
  @media only screen  and (max-width:1520px) {
    grid-template-columns: 100px 200px 220px 103px 220px 95px 10px;
  }
  .specify-parameters-item {
    text-align: center;
  }
}

.campaign-grid-header{
  display: grid;
  //grid-template-columns: repeat(4, 1fr);
  grid-template-columns: 10% 55% 15% 20%;
  grid-gap: 5px;
  font-size: 12px;
  border-bottom: 1px solid #CCCCCC;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: bold;
  text-align: center;
}
.campaing-list-item{
  padding: 2px;
  border: 2px solid #757171;
  margin-top: 5px;
  font-size: 12px;
  min-height: 80px;
  &.selected,
  &.active{
    border-color: $yellow-dark;
  }
}
.campaign-list-grid{
  display: grid;
  //grid-template-columns: repeat(4, 1fr);
  grid-template-columns: 10% 55% 15% 20%;
  grid-gap: 5px;
  font-size:1em;
  text-align: center;

}
.campaign-list-grid-item.campaign-code{
  padding: 8px 5px;
  background-color: #595959;
  color: $white;
}

.input-grid-list{
  display: grid;
  grid-template-columns: 80px 1fr 1fr 1fr 1fr 1fr 30px;
  grid-gap: 10px;
  max-width: 535px;
}

.input-grid-header{
  display: grid;
  grid-template-columns: 100px 1fr 1fr 1fr 1fr 1fr 30px;
  max-width: 535px;
  .input-grid-item{
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.champion-schedule-input-grid,
.challenger-schedule-input-grid{
  display: grid;
  grid-template-columns: 90px 1fr 1fr 1fr 1fr 1fr 30px;
  grid-gap: 10px;
  max-width: 550px;
}
.challenger-schedule-list,
.champion-schedule-list{
  max-width: 550px;
}

.communication-grid-header{
  display: grid;
  // grid-template-columns: 100px 1fr 100px 1fr 30px;
  grid-template-columns: 65px 170px 80px 110px 63px;
  grid-gap: 10px;
  // .communication-item:first-child{
  //   grid-column: 1 / span 2
  // }
}

.communication-grid{
  display: grid;
  grid-template-columns: 65px 170px 80px 1fr 30px;
  grid-gap: 10px;
  .communication-item{
    margin-top: 10px;
  }
}

#payOffDataTable {
  &.table {
    tbody {
      td {
        background: #ededed;
      }
    }
  }
}
[data-theme="dark"] {
  #payOffDataTable {
    &.table {
      tbody {
        td {
          background: #2c2929;
        }
      }
    }
  }
  label[for]{
    svg{
      filter: invert(0);
    }
  }
}

label[for]{
  svg{
    filter: invert(0.4);
  }
}

.lms-card.preview-card {
  .preview-card-inner{
    max-height: 1000px;
    overflow-y: auto;
  }
}

.preview-content-detail{
  background-color: #f8f9fc !important;
  margin: 0 !important;
  padding: 0 !important;
  min-height: 894px;
  color: #000000 !important;
  &.pad-15{
    padding: 50px !important;
  }
}
span.text-phone {
  min-width: 100px;
  display: inline-block;
}
.pref-icon{
  margin-left: -24px;
  min-width: 25px;
  padding-right:0px !important;
}
.breakdown-progress{
  overflow: visible;
  height: 7px;
  .progress-bar{
    overflow: visible;
    span{
      margin-top: 30px;
      color: #111;
    }
  }
}

@media screen and (min-width:1440px) {
.active-collections-scorecards-list,
.max-70,
.nbar-calculation {
    max-width: 70%;
  }
  .departments-max{
    max-width: 75%;
  }
}

// notification page css
.notification-body-header,
.preview-content-detail{
    .EXECUTIVE_WITH_DARK_LOGO {
        background-color: #1b1b1b;
        padding: 10px;
    }
    .EXECUTIVE_WITH_LIGHT_LOGO {
        background-color: #f2f2f2;
        padding: 10px;
    }
}

.roles-table {
    .permission-custom{
        .custom-checkbox{
            .custom-checkbox-disable:checked ~ .custom-control-label::after {
                filter: grayscale(1);
            }
        }
    }
}

.notification-email-form{
    .notification-email-wrapper{
        background: #e7e5e6;
    }
    p {
        font-size: 14px;
        font-weight: 600;
    }
    .help-text {
        font-weight: 400;
    }
}

.contact-list-grid{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  grid-gap: 10px;
  margin-bottom: 5px;
  .contact-list-item{
    position: relative;
  }
  .custom-checkbox {
    margin-bottom: 0px;
    margin-top: 0px;
    .custom-control-label {
      &:before,
      &:after {
        top: 6px;
        left: -22px;
        background-color: $white;
        border: 1px solid #ccc;
      }
    }
    .custom-control-input:checked ~ .custom-control-label::after {
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAPCAYAAADphp8SAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAK+SURBVHgBlVJbSJNRHD/n+84u6m7qmlMnm5cRlpmEgUkmRiQYJREViCKSUupTBJJvIRGBMYIKH1IjegjMhxIjIUshI6FVGupcW87mptnGnJu7fPsup++bFCJZ6/d0OOfP7/+7HAj+Ay99N5Vv1l/X2SPmDorzKQgAWAYjcSF5pJ9IlMSMzSLrhm3/CmVvjbA+AwlAWowl1Bkiw9dK7dnEiSyeYf1yzN7gpRf3IggAgwGQI/VqtqSoNz2VsCZE1OfpkNtCb88sUB/PEYAjAE8CgSSSl1L8SCc1vCiFF2kkDA78uCcDxNqeALVKl2QVzQgPW4m4GFXmpqwtFBdUiOJqIN4l0r1Tg4LHBm2tC4DbAA3gAdLpHDq0EP7USUE66nG570/gvtHD8EJQILEHpzV3lhuu+mLuXCSkG7eU4ciVHugr19dYS2EVI8wh0uvWfGccl1z0lwp+GzMbCOrZbzh/ZMnUX6277O9Zaat3RC0VYoIlsGAJyoIaUd5gkaqKt3Qq/Es1WgxNNzsiU8fEMG4TRTjP7rnQWDuSccmsD8+a/UPNCNIiIUwGQKxEqfPF8qpnJ5StfgDafttHEiRRAYylQgtifhoDhoxxfoM5+Lzzc2h0g+JC6SSx2ZIIKlcKk8sfFGpOv4cQ4q05krVXTk5oJVnOCEsVrLP+VJJvhYBCKxxiOTqZP0PBEglk3tykfU/bDN23jLAkur1Zctg0To+YBufWOWwOcl5ViA1m86okQIgDbg7R/HY10o03ZHZ3GaXly3/6IvH6ITRSGOPJKFi7bglI/G7KVsOAQKagTmgpBardlYq63lJF9TzYAXD7xSvvw+yJjSd1C+GZJj/jMgJAcAflx02Nhp6uHJgTSZhIgA1PKoaX7h61hD80ppAq6fm09pay9HoX+AvgTg9jY9fQtN6ZrxRrk5p0N6bAP/ATj4gvjHZ9UikAAAAASUVORK5CYII=");
      background-size: 13px;
    }
    .custom-control-input:disabled ~ .custom-control-label{
      color: $white;
      cursor: not-allowed;
    }
  }
}
.contact-list{
  .card-form-content:not(.form-vertical) .form-group-label:before{
    left: 0px;
  }
}
.contact-list-inner-grid{
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  align-items: center;
  .contact-list-inner{
    text-align: center;
  }
}


.credit-report-section{
    .custom-checkbox {
        margin-bottom: 0px;
        margin-top: 0px;
        .custom-control-label {
            &:before,
            &:after {
                top: 7px;
                left: -22px;
                background-color: $white;
                border: 1px solid #ccc;
                width: 23px;
                height: 23px;
            }
        }
        .custom-control-input:checked ~ .custom-control-label::after {
            background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAPCAYAAADphp8SAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAK+SURBVHgBlVJbSJNRHD/n+84u6m7qmlMnm5cRlpmEgUkmRiQYJREViCKSUupTBJJvIRGBMYIKH1IjegjMhxIjIUshI6FVGupcW87mptnGnJu7fPsup++bFCJZ6/d0OOfP7/+7HAj+Ay99N5Vv1l/X2SPmDorzKQgAWAYjcSF5pJ9IlMSMzSLrhm3/CmVvjbA+AwlAWowl1Bkiw9dK7dnEiSyeYf1yzN7gpRf3IggAgwGQI/VqtqSoNz2VsCZE1OfpkNtCb88sUB/PEYAjAE8CgSSSl1L8SCc1vCiFF2kkDA78uCcDxNqeALVKl2QVzQgPW4m4GFXmpqwtFBdUiOJqIN4l0r1Tg4LHBm2tC4DbAA3gAdLpHDq0EP7USUE66nG570/gvtHD8EJQILEHpzV3lhuu+mLuXCSkG7eU4ciVHugr19dYS2EVI8wh0uvWfGccl1z0lwp+GzMbCOrZbzh/ZMnUX6277O9Zaat3RC0VYoIlsGAJyoIaUd5gkaqKt3Qq/Es1WgxNNzsiU8fEMG4TRTjP7rnQWDuSccmsD8+a/UPNCNIiIUwGQKxEqfPF8qpnJ5StfgDafttHEiRRAYylQgtifhoDhoxxfoM5+Lzzc2h0g+JC6SSx2ZIIKlcKk8sfFGpOv4cQ4q05krVXTk5oJVnOCEsVrLP+VJJvhYBCKxxiOTqZP0PBEglk3tykfU/bDN23jLAkur1Zctg0To+YBufWOWwOcl5ViA1m86okQIgDbg7R/HY10o03ZHZ3GaXly3/6IvH6ITRSGOPJKFi7bglI/G7KVsOAQKagTmgpBardlYq63lJF9TzYAXD7xSvvw+yJjSd1C+GZJj/jMgJAcAflx02Nhp6uHJgTSZhIgA1PKoaX7h61hD80ppAq6fm09pay9HoX+AvgTg9jY9fQtN6ZrxRrk5p0N6bAP/ATj4gvjHZ9UikAAAAASUVORK5CYII=");
            background-size: 13px;
        }
    }
}

.confirm-box-btn{
    button{
        font-weight: 600;
    }
}
.login-section{
     margin-top: -60px;
    .login-form-content{
        padding: 10px 50px;
    }
    .page-heading{
        padding: 10px 0;
    }
    .login-footer{
        padding: 5px 15px;
    }
    .login-header{
        padding: 8px 15px;
    }
    .login-form-wrapper .login-form-inner {
        min-height: calc(100vh - 130px);
    }
}

/* scoring Module Sidebar */
.scoring-module-sidebar{
  .lms-notification-sidebar .lms-notification-body,
  .lms-notification-sidebar .lms-datawarehouse-body,
  .lms-datawarehouse-sidebar .lms-notification-body,
  .lms-datawarehouse-sidebar .lms-datawarehouse-body {
    padding: 0px;
    min-height: 675px;
    max-height: calc(1938px / 2);
    overflow: auto;
  }
}
// standard settings page css
.segmentation-department-graph{
    .apexcharts-xaxis{
        text {
            transform: none !important;
        }
    }
    .apexcharts-theme-dark{
        .apexcharts-svg {
            background: #1b1b1b47 !important;
        }
    }
    label.form-group-label {
        font-weight: 500;
    }
    .bold-font{
        font-weight: 700;
        font-size: 16px;
    }
}
[data-theme=dark] .segmentation-department-graph{
  .apexcharts-theme-dark{
    .apehxcarts-svg{
      background: #bfbfbf;
    }
  } 
}

// save changes modal popup
.modal-dialog.modal-dialog-centered.modal-lg{
    .confirm-box-btn {
        display: block;
        padding-bottom: 30px;
        button.btn-block {
            width: 60%;
            padding: 10px 0;
            margin: 0 auto;
            margin-bottom: 15px;
            font-size: 16px;
        }
    }
}
// Hide all graph css

// .hidden-graph{
//   display: none;
// }

.hide-graph-img{
  display: none;
}

[data-theme=dark] .daily-report-wrapper{
    .category-listing.level-1{
        .card-header{
        .btn-link,
          button.accordion-button{
              color: #ffffff !important;
            }
        }
    }
}
.daily-report-wrapper{
    .category-listing.level-1{
        .card-header{
        .btn-link,
        button.accordion-button{
                color: #333333 !important;
            }
        }
    }
}

.category-listing .category-items a:hover{
  text-decoration: underline !important;
}

.segmentation-department-wrapper,
.charge-off-checkbox,
.applicant-information,
.specify-rule-item.risk-recovery,
.companies-wrapper,
.paynearme-section{
    .custom-checkbox {
        margin-bottom: 0px;
        margin-top: 0px;
        .custom-control-label {
            &:before,
            &:after {
                top: 6px;
                left: -15px;
                background-color: $white;
                border: 1px solid #ccc;
                width: 18px;
                height: 18px;
            }
        }
        .custom-control-input:checked ~ .custom-control-label::after {
            background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAPCAYAAADphp8SAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAK+SURBVHgBlVJbSJNRHD/n+84u6m7qmlMnm5cRlpmEgUkmRiQYJREViCKSUupTBJJvIRGBMYIKH1IjegjMhxIjIUshI6FVGupcW87mptnGnJu7fPsup++bFCJZ6/d0OOfP7/+7HAj+Ay99N5Vv1l/X2SPmDorzKQgAWAYjcSF5pJ9IlMSMzSLrhm3/CmVvjbA+AwlAWowl1Bkiw9dK7dnEiSyeYf1yzN7gpRf3IggAgwGQI/VqtqSoNz2VsCZE1OfpkNtCb88sUB/PEYAjAE8CgSSSl1L8SCc1vCiFF2kkDA78uCcDxNqeALVKl2QVzQgPW4m4GFXmpqwtFBdUiOJqIN4l0r1Tg4LHBm2tC4DbAA3gAdLpHDq0EP7USUE66nG570/gvtHD8EJQILEHpzV3lhuu+mLuXCSkG7eU4ciVHugr19dYS2EVI8wh0uvWfGccl1z0lwp+GzMbCOrZbzh/ZMnUX6277O9Zaat3RC0VYoIlsGAJyoIaUd5gkaqKt3Qq/Es1WgxNNzsiU8fEMG4TRTjP7rnQWDuSccmsD8+a/UPNCNIiIUwGQKxEqfPF8qpnJ5StfgDafttHEiRRAYylQgtifhoDhoxxfoM5+Lzzc2h0g+JC6SSx2ZIIKlcKk8sfFGpOv4cQ4q05krVXTk5oJVnOCEsVrLP+VJJvhYBCKxxiOTqZP0PBEglk3tykfU/bDN23jLAkur1Zctg0To+YBufWOWwOcl5ViA1m86okQIgDbg7R/HY10o03ZHZ3GaXly3/6IvH6ITRSGOPJKFi7bglI/G7KVsOAQKagTmgpBardlYq63lJF9TzYAXD7xSvvw+yJjSd1C+GZJj/jMgJAcAflx02Nhp6uHJgTSZhIgA1PKoaX7h61hD80ppAq6fm09pay9HoX+AvgTg9jY9fQtN6ZrxRrk5p0N6bAP/ATj4gvjHZ9UikAAAAASUVORK5CYII=");
            background-size: 15px;
        }
    }
}

.new-action-btn.form-action-buttons.justify-content-end{
  left: 17px !important;
  bottom: -15px !important;
}
// bashboard page Bucket 2+ Distribution
.bucket-chart-width{
    .apexcharts-inner {
        transform: translate(40px, 60px);
    }
    .apexcharts-svg {
        height: 285px !important;
    }
}

.option-bgcolor{
    option {
        background: #00B050;
    }
}

.applicant-information .card-form-content.card-form-success .form-control.disabled {
  border-color: #888888;
}

[data-theme=dark] .header-search-wrapper  .header-search-list{
    background: #1b1b1b;
    li:hover{
        background: #565656;
    }
}

.header-search-wrapper {
    position: relative;
    .header-search-list {
        position: absolute;
        left: 82px;
        min-width: 548px;
        right: 0;
        border: 1px solid #565656;
        background: #f2f2f2;
        height:180px;
        overflow-y: auto;
        ul li {
            list-style: none;
            padding: 4px 0 4px 40px;
            font-size: 14px;
            cursor: pointer;
        }
        ul {
            margin: 0;
            padding: 10px 0 0 0;
        }
        li:hover{
            background:#cccccc;
            width: 100%;
        }
    }
}

// green btn color
.btn-primary.bg-green-btn,
.btn-primary.bg-green-btn:hover,
.btn-primary.bg-green-btn:focus,
.btn-primary.bg-green-btn:active{
    background-color: #00B050 !important;
    border-color: #00B050 !important;
}

.new-action-btn{
    .form-group-label:before{
        position: relative !important;
        background: transparent !important;
        top: 0 !important;
        height: auto !important;
    }
    .form-group-label{
        padding-top: 0 !important;
    }
}

.export-link{
  font-size: 14px !important;
  color: #00b0f0 !important;
}
.table-stripped-row-dark {
    background-color:#f7f7f7;
}
.table-stripped-row-light {
  background-color:#ffffff;
}
[data-theme="dark"] .table-stripped-row-dark {background-color:#393939;}
[data-theme="dark"] .table-stripped-row-light {background-color:#404040;}
.card-form-content:not(.form-vertical) .form-group-label.hide-before-border:before{
  content: "";
  height: 0;
  border: 0;
}
.balance-after-border{
  border-bottom: 1px solid #cccccc;
}

#MaxLimitsCommunications{
    .card-form-content.card-form-success {
        border-bottom: 1px solid #888888;
        padding-bottom: 30px;
    }
    .before-border{
        position: relative;
    }
    .before-border:before {
        content: "";
        position: absolute;
        left: 0;
        top: 35px;
        height: 1px;
        width: 100%;
        background: #888888;
        z-index: 1;
    }

    .select-state:before {
        position: absolute;
        content: "";
        height: 1px;
        width: auto;
        background: #888888;
        top: 25px;
        left: 35px;
        right: 0;
    }
}

[data-theme="dark"] #MaxLimitsCommunications{
    .select-state {
        position: relative;
        .custom-control-label::before {
            background-color: #ffffff;
            border-color: #ffffff;
            width: 18px;
            height: 18px;
        }
        .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
            background-color: #00EA6A;
            background-image: none;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            left: -20px;
            top: 8px;
        }
        .custom-control-input:checked ~ .custom-control-label::before {
            color: #FFFFFF;
            border-color: #FFFFFF;
            background-color: #FFFFFF;
        }
        label.custom-control-label {
            padding-left: 10px;
        }
    }
    .select-state:before {
        position: absolute;
        content: "";
        height: 1px;
        width: auto;
        background: #888888;
        top: 25px;
        left: 35px;
        right: 0;
    }
}
.waterfall-form-error{
    .text-danger > div {
        margin-top: 8px;
    }
}
.queue-lastwork-info{
    width: 100%;
    .blue-color{
        color: #9DC3E6;
    }
    .center-queue-text{
        width: 348px;
        margin: 0 auto;
    }
}

.payment-custom-code-grid{
  .payment-code-item {
      position: relative;
      .waterfall-sequence-item {
          position: absolute;
          right: -15px;
          top: 0;
      }
  }
}

.form-errors{
  .text-danger > div {
      margin-top: 8px;
  }
}
.lms-text-light-green{
  color: #276c32;
    font-weight: 600;
}

[data-theme="dark"]{
  .lms-text-light-green {
    color: #36f154;
  }
    .text-underline{
        span {
            color: #ffffff;
        }
    }
}
.text-underline{
    span {
        text-decoration: underline;
        color: #3A3838;
    }
}
.component-host-scrollable{
  overflow-y: auto !important;
}


/*Col 40% Width*/
.col-40p{
  max-width:40%;
}
/*Col 40px Width*/
.col-40{
  min-width:40px;
  max-width:40px;
}
/*Col 50px Width*/
.col-50{
  min-width:50px;
  max-width:50px;
}
/*Col 60px Width*/
.col-60px{
  min-width:60px;
  max-width:60px;
}
/*Col 70px Width*/
.col-70px{
  min-width:70px;
  max-width:70px;
}
/*Col 100px Width*/
.col-100px{
  min-width:100px;
  max-width:100px;
}
/*Col 110px Width*/
.col-110px{
  min-width:110px;
  max-width:110px;
}
/*Col 120px Width*/
.col-120px{
  min-width:120px;
  max-width:120px;
}
/*Col 130px Width*/
.col-130px{
  min-width:130px;
  max-width:130px;
}

/*Col 150px Width*/
.col-150px{
  min-width:150px;
  max-width:150px;
}
/*Col 180px Width*/
.col-180px{
  min-width:180px;
  max-width:180px;
}
/*Col 300px Width - odds of roll chart*/
.w300px{
  width:300px;
}
/*Width 20px */
.w20px{
  width:20px;
}
/*Width 38px */
.w38px{
  width:38px;
}
/* AH app & co-app */
.col_30w{
  flex: 0 0 28%;
  width:28%;
  min-width:442px;
  padding-right:10px;
  padding-left:10px;
}
.col_40w{
  flex: 1 0 38%;
  padding-right:10px;
  padding-left:10px;
}
.w100p{
  min-width:100%;
}
.modal-xl{
  max-width:800px;
}
.oflow{
  overflow:auto;
  white-space: nowrap;

}
.oflow::-webkit-scrollbar {
  height: 0px;
}
.label-banner{
  color:#D9D9D9;
  text-align:right;
}
[data-theme=light] .label-banner{
  color:#000000;
  text-align: right;
}
.uploadpdf{
  width:95%;
  margin-left:10px;
}
.padL40{
  padding-left:40px;
}
.padL20{
  padding-left:20px;
}
.mB0{
  margin-bottom:0;
}
.mB20{
  margin-bottom:20px;
}
.floatLpadR10{
  float:left;
  padding-right:10px;
}
.print-margin{
  margin: 0.5in;
}
[data-theme=dark] .mapping-button{
  background-color: #404040 !important;
  color:#ffffff !important;
}

[data-theme=dark] .border-section{
  border: 6px solid #808080 ;
  padding: 30px;
  background-color: #404040 !important;
}

.border-section{
  border: 6px solid #808080;
  padding: 30px;
  background-color: #ffffff;
}
.infotip img{
  margin-top:-10px;
}
.risk-rating-mix-calculation-item{
  val-errors.error-div.text-danger div {
      height: 5px;
  }
}
.cursor-pointer{
  cursor: move !important;
}
.cursor-pointer-template{
  cursor: pointer;
}

.best-time-call-table{
    border-collapse: collapse;
    width: 100%;
    text-align: center;
    background-color: #262626;
    border-color:#888888 !important;
    .main-heading{
      width: 100px;
      background-color: #000000;
    }
    th{
        font-size: 13px;
    }
}

[data-theme="light"] .best-time-call-table{
  background-color: #efefef;
}
[data-theme="light"] .best-time-call-table .main-heading{
  background-color: #ccc;
}
.content-hidden{
  display: none;
}

body.modal-open,body{
  padding-right: 0 !important;
}
.dashboard2-graph{
    .bucket-chart-width{
        .apexcharts-svg {
          height: auto !important;
        }
        .apexcharts-inner {
            transform: translate(40px, 45px);
        }
    }
}
.ng-dropdown-panel.input-scorecard-field{
    z-index: 6 !important;
}
.input-scorecard-field.ng-select.ng-select-opened{
    .ng-select-container{
        z-index: 6 !important;
    }
}

.ng-dropdown-panel.input-scorecard-field{
    z-index: 6 !important;
}
.input-scorecard-field.ng-select.ng-select-opened{
    .ng-select-container{
        z-index: 6 !important;
    }
}

[data-theme=dark] .row-background{
  background-color:#000000 ;
  color:#ffffff;
}
[data-theme=light] .row-background{
  background-color:#e9e7e7 !important;
  color:#000000;
}
body.modal-open,body{
  padding-right: 0 !important;
}


[data-theme=dark] .queue-sorting-ui .chips{
    background-color: #323131 !important;
}
.queue-sorting-ui{
    .sort-chips{
        text-align: right;
        label{
        padding-right: 8px;
        }
    }
    .chips{
        box-shadow: none;
        font-weight: 600;
        line-height: 1;
        opacity: 0.9;
        text-shadow: none;
        font-size: .9em;
        background-color: #bfbfbf !important;
        color: #ffffff !important;
        padding: 4px 4px 0px 8px;
        label{
            padding: 6px 0px 0px 4px;
        }
        .close{
            cursor: pointer;
            padding: 0px 4px 0px 8px;
            font-size: 15px;
            font-weight: 700;
            line-height: 23px;
        }
    }
    .sorting-block{
        display: flex;
        align-items: center;
        vertical-align: middle;
    }
}
// Queue Detail page circle colored graph css
[data-theme=dark] .queue-detail-circle-graph{
    .circle-div{
        /*border: 1px solid #948787;*/
        background-color: #1B1B1B;

        p{
            color: #ffffff;
        }
    }
    .circle-graph-block{
        background-image: url("~/src/assets/images/client/Odds of Roll Dark Mode.svg");
    }

    .odds-roll-builder{
        .circle-div{
            background-color: #404040;
        }
    }
}

.queue-detail-circle-graph{
    .circle-graph-block{
        background-image: url("~/src/assets/images/client/Odds of Roll Light Mode.svg");
        background-repeat: no-repeat;
        height: 200px;
        width: 300px;
        position: relative;
        margin-top: 36px;
        margin: 0 0 0 auto;
    }
    .dial {
        transform-origin: bottom center;
        transition: 0.75s;
        width: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 94px solid #ffff00;
        position: absolute;
        top: 16%;
        right: 48%;
        animation: fill ease-in 3s;
        // transform: scale(1.1) rotate(40deg);
    }
    .circle-div{
        /*border: 1px solid #948787;*/
        position: absolute;
        top: 47%;
        left: 37%;
        height: 22%;
        width: 25%;
        border-radius: 150px 150px 0 0;
        background-color: #ffffff;
        p{
            color: #323131;
            font-size: 14px;
            font-weight: 700;
            margin-top: 17%;
        }
    }
}

.progress.breakdown-progress{
    .progress-bar.breakdown-active{
        span {
            margin-top: 65px;
            color: #03f772 !important;
        }
    }
    .progress-bar.breakdown-charge{
        span {
            margin-top: 65px;
            color: #ff6262 !important;
        }
    }
    .progress-bar.breakdown-repo{
        span {
            color: #ffcd03 !important;
        }
    }
    .progress-bar.breakdown-paid{
        span {
            color: #03bcff !important;
        }
    }
}


.apexcharts-legend-marker2{

  margin-top: -6px;
  width: 12px;

}

.apexcharts-legend-marker3{
  margin-top: -7px;
  margin-left: -2px;
  width: 12px;

}
.progress.breakdown-progress{
  .progress-bar.breakdown-active{
      span {
          margin-top: 65px;
          color: #03f772 !important;
      }
  }
  .progress-bar.breakdown-charge{
      span {
          margin-top: 65px;
          color: #ff6262 !important;
      }
  }
  .progress-bar.breakdown-repo{
      span {
          color: #ffcd03 !important;
      }
  }
  .progress-bar.breakdown-paid{
      span {
          color: #03bcff !important;
      }
  }
}

.apexcharts-legend-marker-dashboard{

  margin-top: -6px;
  margin-left: -8px;
  width: 20px;
  height: 15px;

}
.specify-rule-item .col-180px
{
  min-width: inherit;
  max-width: inherit;
  }
.lms-modal-card-bg.mapping-tool{
    .lms-card-header{
        background-color: #E7E5E6;
        padding: 0 5px;
    }
}
[data-theme=dark] .lms-modal-card-bg.mapping-tool .lms-card-header{
    background-color: #2A2A2A;
}

.format-table{
    td{
        padding: 5px 30px;
    }
    .format-text{
        // .modal-title-success{
        //     width: 252px;
        // }
        td{
            padding: 0;
        }
    }
    .form-control:not([disabled]){
        border: 0 !important;
    }


}
.format-table-wrapper.card-form-content:not(.form-vertical) .form-group-label::before{
    height: 1px !important;
}
.border-grey1{
    border-bottom: 2px solid #888888 !important;
}
.specify-rule-item .col-180px
{
  min-width: inherit;
  max-width: inherit;
  }

.apexcharts-legend {
  overflow: hidden !important;
}

.lms-modal-card-bg.mapping-tool{
    .lms-card-header{
        background-color: #E7E5E6;
        padding: 0 5px;
    }
}
[data-theme=dark] .lms-modal-card-bg.mapping-tool .lms-card-header{
    background-color: #2A2A2A;
}

.format-table{
    td{
        padding: 5px 30px;
    }
    .format-text{
        // .modal-title-success{
        //     width: 252px;
        // }
        td{
            padding: 0;
        }
    }
    .form-control:not([disabled]){
        border: 0 !important;
    }


}
.format-table-wrapper.card-form-content:not(.form-vertical) .form-group-label::before{
    height: 2px;
}
.border-grey1{
    border-bottom: 2px solid #888888 !important;
}
.send-notification-model-box{
  .note-text{
    color: #404040;
  }
}

[data-theme=dark] .send-notification-model-box .note-text{
  color: rgb(229, 225, 225) !important;
}
.notification-body-content{
  img{
    z-index:0 !important;
  }
}
[data-theme=dark] .card.new-block .submenu-items a:hover,
[data-theme=dark] .card.new-block .submenu-items a.active{
  color: #ffffff !important;
  font-weight: 500;
}

[data-theme=dark] .card.new-block {
  background-color: #262626;
}

.open-source-model-box{
    ng-select.form-control.form-control-sm {
        height: auto !important;
        padding: 0 !important;
        .ng-select-container{
            min-height: auto !important;
        }
    }
    .ng-value-container .ng-input>input {
        color: #ffffff !important;
    }
}
[data-theme=dark] .yellow-color{
  color:#ffff00;
}

.yellow-color{
  color:#D68910;
}

.datawarehouse-rightside{
    .lms-datawarehouse-body {
        height:110vh;
        overflow-y: scroll;
    }
    .bg-dark-blue {
        margin-top: 0 !important;
    }
}

[data-theme=dark]  .datawarehouse-wrapper .module-tabs.nav-tabs .nav-link.active,
[data-theme=dark]  .datawarehouse-wrapper .module-tabs.nav-tabs .nav-item.show .nav-link{
    background: #000000;
}

.datawarehouse-wrapper .module-tabs.nav-tabs .nav-link.active,
.datawarehouse-wrapper .module-tabs.nav-tabs .nav-item.show .nav-link{
    border-color: #0070C0 #0070C0 #565656 !important;
    background: #bfbfbf;
}
[data-theme=dark] .add-bgcolor {
    background: #393939 !important;
}
.add-bgcolor {
    background: #bfbfbf;
    padding: 20px;
}

[data-theme=dark] .custom-datawarehouse tr.category-listing {
    background: #ffffff !important;
    color: #393939 !important;
}

.custom-datawarehouse tr.category-listing {
    background:rgb(191, 191, 191) !important;
    color: #ffffff !important;
}

.dataTables_processing {
    // margin-left: -300px !important;
    left: 38% !important;
}

// calendar overlap css
ngb-datepicker.dropdown-menu {
  z-index: 9;
}

label.custom-control.custom-checkbox{
  width:0px;
  padding-left: 0;
  margin-left: 1.5rem;
  .custom-control-input {
    top: 6px;
    left: -15px;
  }
}

.companies-wrapper{
  .form-group label,
  .form-group-label {
    font-size: 14px;
    line-height: 23px;
  }
}
[data-theme=dark] .theme-green .bs-datepicker-head {
    background-color: #101214 !important;
    button{
      color: #fff !important;
    }
  }
  [data-theme=dark]  .bs-datepicker-body table td span.selected,
  [data-theme=dark]  .bs-datepicker-body table td.selected span,
  [data-theme=dark]  .bs-datepicker-body table td span[class*=select-]:after,
  [data-theme=dark] .bs-datepicker-body table td[class*=select-] span:after{
    background-color: #00b0f0 !important;
  }
  .bs-datepicker-container {
     padding:0px !important;
}
.theme-green{
  .bs-datepicker-head {
    background-color: #f8f9fa !important;
    border: 1px solid #cccccc;
    button{
      color: #101214 !important;
    }
  }
  .bs-datepicker-body table td span.selected,
  .bs-datepicker-body table td.selected span,
  .bs-datepicker-body table td span[class*=select-]:after,
  .bs-datepicker-body table td[class*=select-] span:after{
    background-color: #00b0f0 !important;
  }
}
.bs-datepicker-body table td {
    color: #000000 !important;
}

bs-datepicker-container, bs-daterangepicker-container{
  z-index: 1 !important;
}

.ng-dropdown-panel,
.ng-select-container,
.ng-select.ng-select-opened .ng-select-container  {
  z-index: 1 !important;
}

ngx-extended-pdf-viewer .html {
  min-height: 1000px;
  overflow: auto;
}
ngx-extended-pdf-viewer .html {
  min-height: 1000px;
  overflow: auto;
}

.characteristic-container,
.assignment-container{
  ngb-datepicker.dropdown-menu.show {
    bottom: 0 !important;
  }
}
  .open-source-model-box{
    ng-select.form-control.form-control-sm {
        height: auto !important;
        padding: 0 !important;
        .ng-select-container{
            min-height: auto !important;
        }
    }
    .ng-value-container .ng-input>input {
        color: #ffffff !important;
    }
  }

.characteristic-container,
.assignment-container{
  ngb-datepicker.dropdown-menu.show {
    bottom: 0 !important;
  }
}


.accordion-item {
  position: relative;
}
[data-theme=dark]  .ngb-dp-header {
  background: #ffffff !important;
  select.form-select {
    color: #dee2e6;
    background-color: #101214;
    border-color: #6c757d;
  }
}
.file-wrapper{
  .ngb-dp-weekday{
    color: var(--info);
  }
}

.ngb-dp-header {
  background: #F2F2F2 !important;
  select.form-select {
    color: #dee2e6;
    background-color: #F2F2F2;
    border-color: #6c757d;
  }
}
div#page-content-wrapper\ file-wrapper{
  .ngb-dp-weekdays{
    color: #00b0f0;
  }

}
.ngb-dp-weekdays{
  background-color: #f8f9fa !important;
  border-bottom: 1px solid rgba(0,0,0,.125) !important;
  }
.ngb-dp-header {
  background: #F2F2F2 !important;
  select.form-select {
    color: #101214;
    background-color: #fff;
    border-color: #6c757d;
  }


}
[data-theme=light] .category-listing .btn-link,
[data-theme=light] .category-listing button.accordion-button {
    background-color: #999999 !important;
}
[data-theme=light] .category-listing.level-1 .accordion-header .btn-link {
    color: #fff !important;
    padding: 5px 15px 5px 34px;
    background-color: #404040;
}
.cke_notification.cke_notification_warning
{
display: none !important;
}
[data-theme=dark] .lms-queue-select-boxes{
  background-color: transparent;
  padding: 0px 0;
  margin-bottom: 0px;
}
.lms-queue-select-boxes{
  background-color: #9e9e9e;
  padding: 5px 0;
  margin-bottom: 15px;
}

[data-theme=light] #NetBalAtRisk .apexcharts-canvas,
[data-theme=light] .apexcharts-canvas{
  .apexcharts-svg{
    background:#f0f0f0 !important;
  }
}
[data-theme=light] #NetBalAtRisk .apexcharts-canvas .apexcharts-svg,
[data-theme=light] .apexcharts-canvas .apexcharts-svg {
  background: #f0f0f0 !important;
}
.custom-control{
  z-index: 0 !important;
}

.blackpatch{background-color: #3A3838 !important;}
[data-theme=light] .blackpatch{background-color: #f0f0f0 !important;}
.modal.popup-outside-editable {  pointer-events: none; /* Allows interaction with elements behind the modal */}
.popup-outside-editable .modal-content {  pointer-events: all; /* Allows interaction only within the modal content */} 
.modal-open {  overflow: auto !important;  /* Enable scrolling */}/* Hide the backdrop to make background clickable and editable */
.modal-backdrop {  display: none !important;   /* Remove backdrop */}

[data-theme=light] .manual-charge-off-wrapper .btn-help-info span 
{
  background-color: #d2d1d1!important;
  color: #565656;
}

// select-box light theme issue

[data-theme=light] .lms-queue-select-boxes .ng-select .ng-select-container,
[data-theme=light] .lms-queue-select-boxes .ng-select .ng-select-container .ng-value-container .ng-placeholder,
[data-theme=light] .lms-queue-select-boxes .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  color:#212529 !important;
}
[data-theme=light] .lms-queue-select-boxes .ng-dropdown-panel-items .ng-option:hover{
  background-color: #44b051 !important;
}
.lms-queue-select-boxes .ng-select {
  border: 1px solid #dddddd;
}
.lms-queue-select-boxes .ng-dropdown-panel-items .ng-option {
  background: #000000 !important;
  color: #ffffff !important;
}
[data-theme=light] .lms-queue-select-boxes .ng-dropdown-panel-items .ng-option {
  background: #ffffff !important;
  color: #000000 !important;
}
 
[data-theme=light] .ng-select .ng-select-container{   
  color: #3a3838 !important;  
}
.ng-dropdown-panel-items .ng-option {    
  background: #000000 !important;    
  color: #ffffff !important;  
}  
[data-theme=light] .ng-dropdown-panel-items .ng-option {    
  background: #f2f2f2!important;    
  color: #000000 !important;  
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel-items .ng-option:hover,
.lms-queue-select-boxes .ng-dropdown-panel-items .ng-option:hover {
  background-color: #44b051 !important;
}

.run-live-report {
  .nav-tabs-rounded .nav-link.active{
  background-color: #00B0F0;
  border-radius: 0 !important;
  border-color: #00b0f0 !important;
  }
  .nav-tabs-rounded{
    background-color: transparent;
    border-radius: 0 !important;
  }
  .nav-link{
    line-height: 16px;
  }
  .nav-link,
  .nav-link:hover {
    // width: 25%;
    text-align: center;
    border: 1px solid;
    border-color: #cccccc !important;
    border-radius: 0;
  }
  .nav-tabs-rounded .nav-link{
    padding: 5px 16px;
  }
}

[data-theme=light] .run-live-report a.nav-link {
    color: #000000;
}
[data-theme=light] .run-live-report a.nav-link.active{
    color:#ffffff;

} 
app-applicant-information,
app-loan-information,
app-loan-side-note,
app-asset-recovery,
.form-control-label-textwrap{
  .card-form-content:not(.form-vertical) {
    .form-group-label {
      margin-bottom: 0px !important;
      &:before {
        content: "";
        top:auto;
        bottom: 0;
      }
    }
  }
  .form-control,
  .form-control-sm{
    height: 100% !important;
    min-height: 31px !important;
  }
  app-date-picker > div{
    height: 100%;
    button.btn.calendar {
      top: 0px;
      bottom: 0;
    }
  }
  .btn-help-info.is-hover {
    display: flex;
    align-items: center;
    height: 100%;
  }

}

app-loan-side-note{
  .card-form-content:not(.form-vertical) {
    .form-group-label {
      margin-bottom: 5px !important;
    }
  }
}
[data-theme=light] .border-white-light-theme{
[data-theme=light] .border-white-light-theme {
  border-color: #888888 !important;
  border-width: 3px !important;
}
[data-theme=light] .border-top-white {
  border-top: 2px solid #888888 !important;
}

.border-top-white{
  border-top: 1px solid #fff;
}
.run-live-report {
  .nav-tabs-rounded .nav-link.active{
  border-radius: 0 !important;
  border-color: #00b0f0 !important;
  }
  .nav-tabs-rounded{
    background-color: transparent;
    border-radius: 0 !important;
  }
  .nav-link{
    line-height: 16px;
  }
  .nav-link,
  .nav-link:hover {
    // width: 25%;
    text-align: center;
    border: 1px solid;
    border-color: #cccccc !important;
    border-radius: 0;
  }
}

[data-theme=light] .border-white-light-theme {
  border-color: #888888 !important;
  border-width: 3px !important;
}
// calculator light theme changes
[data-theme=light] .calculator-screen,
[data-theme=light] .calculator button {
  background: #e7e7e7;
  color: #000000;
}
[data-theme=light] .calculator button.operator:hover{
  background-color: #f2f2f2;
}
[data-theme=light] .progress-record  ngb-progressbar.progress.green-bg {
  border: 1px solid #ccc;
}
[data-theme=light] .tools-dropdowns .dropdown-menu .dropdown-item:active{
    color: #16181b;
}
[data-theme=light] .border-top-white {
  border-top: 2px solid #888888 !important;
}

}

.ng-select.form-control.form-control-sm.ng-select-disabled {
  border-color: #888888 !important;
}

[data-theme=light] .risk-grade-list .risk-grade-item{
  background: #808080;
}
[data-theme=light] .run-live-report a.nav-link {
    color: #000000;
}
[data-theme=light] .run-live-report a.nav-link.active{
    color:#ffffff;
}

